<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddGatewayModal">
    <div *ngIf="!isAvwSitePicker" [hidden]="isSelected" class="fullRow" id="Stage1">
        <div class="title" style="filter: brightness(50%)">Create A New Gateway</div>
        <div class="subtitle">Choose a gateway type</div>
        <div class="tileSized">
            <div
                (click)="setType('GW')"
                *ngIf="featureService.experimentalFeatures && canListDataCenters"
                class="buttonCard selectType"
                id="SelectButtonGW"
            >
                <div class="icon" style="background-image: url(/assets/svgs/Icon_PrivateCloud.svg)"></div>
                <div class="title">Public Internet Gateway</div>
                <div class="content">
                    Optimize access to public internet resources over your NetFoundry Network<br />&nbsp;
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create a Public Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>

            <div
                (click)="setType('AWSCPEGW')"
                *ngIf="canListDataCenters"
                class="buttonCard selectType"
                id="SelectButtonAWS"
            >
                <div class="icon" style="background-image: url(assets/svgs/Icon_PublicGateway.svg)"></div>
                <div class="title">AWS Cloud Gateway</div>
                <div class="content">Connect an AWS VPC to your NetFoundry network.<br />&nbsp;</div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
            <div
                (click)="setType('AwsAutoScale')"
                *ngIf="canListDataCenters && canCreateHaGateways && networkGroup.awsAutoScalingGatewayEnabled"
                class="buttonCard selectType"
                id="SelectButtonAWSAutoScaling"
            >
                <div class="icon" style="background-image: url(assets/svgs/AWSAutoScaling.svg)"></div>
                <div class="title">AWS Auto Scaling Gateway</div>
                <div class="content">
                    Connect an AWS VPC to your NetFoundry network with automatic capacity adjustment.<br />&nbsp;
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
            <div
                (click)="setType('AZCPEGW')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonAzurePublic"
            >
                <div
                    (click)="setType('AZCPEGW')"
                    class="icon"
                    style="background-image: url(assets/svgs/Icon_AzureGateway.svg)"
                ></div>
                <div class="title">Azure Cloud Gateway</div>
                <div class="content">Connect an Azure VNET to your NetFoundry network.</div>
                <div class="row center buttonArea">
                    <button (click)="setType('AZCPEGW')" class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
            <div
                (click)="setType('AZSGW')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonAzureStack"
            >
                <div
                    (click)="setType('AZSGW')"
                    class="icon"
                    style="background-image: url(assets/svgs/Icon_AzureGateway.svg)"
                ></div>
                <div class="title">Azure Stack Gateway</div>
                <div class="content">Connect an Azure Stack to your NetFoundry network.</div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
            <div
                (click)="setType('ZT')"
                *ngIf="zitiEnabledService.zitiEnabled && !zitiEnabledService.zitiPure && canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonZiti"
            >
                <div
                    class="icon"
                    style="background-image: url(assets/svgs/ZitiGateway.svg); background-size: 70%"
                ></div>
                <div class="title">Ziti Bridge Gateway</div>
                <div class="content">
                    Extend your network to even more types of devices with this Ziti Bridge Endpoint.
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>

            <div
                (click)="setType('GCPCPEGW')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonGCPCPEGW"
            >
                <div class="icon" style="background-image: url(assets/svgs/googlecloud.svg)"></div>
                <div class="title">Google Cloud Gateway</div>
                <div class="content">Connect any Google Cloud VPC to your NetFoundry network.<br />&nbsp;</div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>

            <div
                (click)="setType('VCPEGW')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonVCPE"
            >
                <div class="icon" style="background-image: url(assets/svgs/Icon_EdgeGateway.svg)"></div>
                <div class="title">vCPE Gateway</div>
                <div class="content">
                    Connect physical locations like datacenters and remote sites to your NetFoundry network.<br />&nbsp;
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
            <div
                (click)="setType('L2VCPEGW')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonL2VCPEGW"
            >
                <div
                    class="icon"
                    style="background-image: url(assets/svgs/VoIPGateway.svg); background-size: 13.75rem"
                ></div>
                <div class="title">Voice Enhanced Gateway</div>
                <div class="content">
                    Set up your VoIP Network using this VCPE Gateway. For use with the Voice Enhanced AppWAN only.<br />&nbsp;
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
            <div
                (click)="setType('ALICPEGW')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonALIBABA"
            >
                <div
                    class="icon"
                    style="background-image: url(assets/svgs/AlibabaCloud.svg); background-size: 15rem"
                ></div>
                <div class="title">Alibaba Cloud Gateway</div>
                <div class="content">Connect any AliBaba Cloud VPC to your Netfoundry Network.<br />&nbsp;</div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
            <div
                (click)="setType('OCPCPEGW')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonOCPC"
            >
                <div
                    class="icon"
                    style="background-image: url(assets/svgs/OracleCloud.svg); background-size: 15rem"
                ></div>
                <div class="title">Oracle Cloud Gateway</div>
                <div class="content">Connect any Oracle Cloud VPC to your Netfoundry Network.<br />&nbsp;</div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>

            <div
                (click)="setType('BULK_UPLOAD')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonBulkImport"
            >
                <div class="icon" style="background-image: url(assets/svgs/AsCode.svg)"></div>
                <div class="title">IMPORT YOUR GATEWAY AS CODE</div>
                <div class="content">
                    Prefer working in code? Simplify provisioning of your Gateway with a simple file upload.<br />&nbsp;
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create Gateway</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
        </div>
    </div>

    <div *ngIf="isAvwSitePicker" [hidden]="isSelected" class="fullRow" id="Stage1">
        <div class="title" style="filter: brightness(50%)">Create A New Site</div>
        <div class="subtitle">Choose Single or Bulk Creation</div>
        <div class="tileSized">
            <div
                *ngIf="
                    canListGeoRegions &&
                    canListDataCenters &&
                    this.canCreateAzureVirtualWanSites &&
                    this.canListAzureSubscriptions &&
                    this.canListAzureSubscriptions
                "
                class="buttonCard selectType"
                id="SelectButtonAzure"
            >
                <div
                    (click)="setType('AVWGW')"
                    class="icon"
                    style="background-image: url(assets/svgs/AVWSite.svg); background-size: 80%"
                ></div>
                <div (click)="setType('AVWGW')" class="title">Azure Virtual WAN Site</div>
                <div (click)="setType('AVWGW')" class="content">
                    Connect a branch site to Microsoft Azure Virtual WAN.
                </div>
                <div class="row center buttonArea">
                    <button (click)="setType('AVWGW')" class="save">Create a Site</button>
                </div>
            </div>

            <div
                *ngIf="
                    canListGeoRegions &&
                    canListDataCenters &&
                    this.canCreateAzureVirtualWanSites &&
                    this.canListAzureSubscriptions &&
                    this.canListAzureSubscriptions
                "
                class="buttonCard selectType"
                id="SelectButtonAzure"
            >
                <div
                    (click)="setType('AZB')"
                    class="icon"
                    style="background-image: url(assets/svgs/BulkAVWSite.svg); background-size: 80%"
                ></div>
                <div (click)="setType('AZB')" class="title">Create Multiple Sites</div>
                <div (click)="setType('AZB')" class="content">
                    Bulk create multiple sites at once by uploading a simple csv.
                </div>
                <div class="row center buttonArea">
                    <button (click)="setType('AZB')" class="save">Bulk Create Sites</button>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!isSelected" class="fullRow">
        <div *ngIf="selectedType === 'AVWGW'">
            <app-avw-site-gateway-form
                (back)="back()"
                (hide)="hide()"
                [avwSiteModel]="avwSiteModel"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [gatewayModel]="gatewayModel"
                [hideHaUpgrade]="hideHaUpgrade"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-avw-site-gateway-form>
        </div>
        <div *ngIf="selectedType === 'AZB'">
            <app-avw-site-gateway-bulk-form (back)="back()" (hide)="hide()" [isInline]="isInline">
            </app-avw-site-gateway-bulk-form>
        </div>
        <div *ngIf="selectedType === 'GW'">
            <app-public-internet-gateway-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-public-internet-gateway-form>
        </div>
        <div *ngIf="selectedType === 'AWSCPEGW' || selectedType === 'AwsAutoScale'">
            <app-aws-gateway-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isAwsAutoScale]="selectedType === 'AwsAutoScale'"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-aws-gateway-form>
        </div>
        <div *ngIf="selectedType === 'AZCPEGW' || selectedType === 'AZSGW'">
            <app-azure-gateways-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [selectedType]="selectedType"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-azure-gateways-form>
        </div>
        <div *ngIf="selectedType === 'ZT'">
            <app-ziti-gateways-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-ziti-gateways-form>
        </div>

        <div *ngIf="selectedType === 'VCPEGW'">
            <app-vcpe-gateway-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-vcpe-gateway-form>
        </div>

        <div *ngIf="selectedType === 'L2VCPEGW'">
            <app-l2vcpe-gateway-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-l2vcpe-gateway-form>
        </div>

        <div *ngIf="selectedType === 'GCPCPEGW'">
            <app-google-cloud-gateway-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-google-cloud-gateway-form>
        </div>
        <div *ngIf="selectedType === 'ALICPEGW'">
            <app-alibaba-gateway-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-alibaba-gateway-form>
        </div>
        <div *ngIf="selectedType === 'OCPCPEGW'">
            <app-ocpc-gateway-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-ocpc-gateway-form>
        </div>

        <div *ngIf="selectedType === 'BULK_UPLOAD'">
            <app-bulk-upload-gateways-form
                (back)="back()"
                (hide)="hide()"
                (newGateway)="setNewGateway($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
                [hideHaUpgrade]="hideHaUpgrade"
                [isInline]="isInline"
                [showBreakoutFields]="showBreakoutFields"
            >
            </app-bulk-upload-gateways-form>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="CloseGatewayButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
