<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="EditModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span id="GatewayType"></span> {{ isHAGateway ? 'HA ' : '' }}Gateway
        </div>
        <div class="subtitle">Edit your gateway attributes</div>
        <div class="row">
            <div class="midSized">
                <div class="half">
                    <div class="label row">
                        Gateway Name<span [hidden]="!errorName" class="error">Input Required</span
                        ><span [hidden]="errorName" class="note">Required</span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="model.name"
                            [disabled]="(!isHAGateway && !canUpdateGateway) || (isHAGateway && !canUpdateCluster)"
                            [ngClass]="{ error: errorName }"
                            class="restrictNormal"
                            id="EditCurrentName"
                            maxlength="{{ maxLength }}"
                            placeholder="What would you like to call this gateway?"
                            type="text"
                        />
                    </div>
                </div>

                <div *ngIf="showBreakoutFields" class="half padLeft">
                    <div class="label row">
                        O365 Breakout Next Hop IP
                        <span [hidden]="!errorNextHop" class="error">Invalid</span>
                        <span [hidden]="errorNextHop" class="note">Optional</span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="model.o365BreakoutNextHopIp"
                            [ngClass]="{ error: errorNextHop }"
                            class="restrictNormal"
                            id="NextHopIp"
                            placeholder="Next Hop IP"
                            type="text"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="midSized">
                <div *ngIf="!isHAGateway" class="half">
                    <app-endpoint-geo
                        [cloudRegionOnly]="true"
                        [componentId]="model.componentId"
                        [endpoint]="model"
                    ></app-endpoint-geo>
                </div>
                <div *ngIf="isHAGateway" class="half">
                    <app-endpoint-geo
                        [cloudRegionOnly]="true"
                        [cluster]="model"
                        [componentIdList]="componentIdList"
                    ></app-endpoint-geo>
                </div>

                <div *ngIf="!isHAGateway" class="half padLeft">
                    <app-endpoint-geo
                        [cloudRegionOnly]="!canReadElasticSearch"
                        [componentId]="model.componentId"
                        [endpoint]="model"
                        [title]="'Last Known Location'"
                    ></app-endpoint-geo>
                </div>
                <div *ngIf="isHAGateway" class="half padLeft">
                    <app-endpoint-geo
                        [cloudRegionOnly]="!canReadElasticSearch"
                        [cluster]="model"
                        [componentIdList]="componentIdList"
                        [title]="'Last Known Location'"
                    ></app-endpoint-geo>
                </div>
            </div>
        </div>

        <div *ngIf="model.endpointType !== 'L2VCPEGW'" class="row">
            <div class="midSized">
                <div class="half toggleHa">
                    <div *ngIf="!hideHaUpgrade()" [hidden]="netflowEnabled">
                        <div class="label row">Enable High Availablity</div>
                        <div class="row">
                            <div class="darkGrid">
                                <div class="title">
                                    <div class="icon-ha"></div>
                                </div>
                                <div class="subtitle">
                                    ENABLE HIGH AVAILABILITY<br />
                                    FOR ULTIMATE REDUNDANCY
                                </div>
                                <div>
                                    <div class="switchGrid">
                                        <div>
                                            <div
                                                (click)="toggleHaUpgrade()"
                                                [ngClass]="{ on: haUpgrade }"
                                                class="toggle"
                                            >
                                                <span [hidden]="!haUpgrade">ON&nbsp;&nbsp;&nbsp;</span>
                                                <span [hidden]="haUpgrade">&nbsp;&nbsp;&nbsp;OFF</span>
                                                <div class="switch"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <span [hidden]="!haUpgrade">Enabled</span>
                                            <span [hidden]="haUpgrade">Disabled</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showExportForm()" class="half padLeft">
                    <div *ngIf="collectors.length > 0" class="label row labelExpansion">
                        Advanced Options<span class="note">Open to Edit Details</span>
                    </div>
                    <div *ngIf="collectors.length > 0" class="row expansion">
                        <mat-accordion>
                            <mat-expansion-panel #panel [hideToggle]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'4.0625rem'">
                                    <mat-panel-title class="expansionLabel">
                                        <div class="title">
                                            <div class="icon-AdvancedOptions"></div>
                                        </div>
                                        <div class="subtitle">Advanced Options</div>
                                        <span
                                            [ngClass]="{ open: panel.expanded }"
                                            class="expansion-indicator icon-Sort_Down"
                                        ></span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-grid-list cols="20" rowHeight="2.8125rem">
                                    <mat-grid-tile [colspan]="16">
                                        <div class="label netflowFieldLabel">Netflow</div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="4">
                                        <div class="toggleTile">
                                            <div
                                                (click)="!hideNetflowFields && toggleNetflowEnabled()"
                                                [ngClass]="{ on: netflowEnabled }"
                                                class="toggle gridToggle"
                                                id="NetflowToggle"
                                            >
                                                <span [hidden]="!netflowEnabled" id="NetflowToggleOn"
                                                    >ON&nbsp;&nbsp;&nbsp;</span
                                                >
                                                <span [hidden]="netflowEnabled" id="NetflowToggleOff"
                                                    >&nbsp;&nbsp;&nbsp;OFF</span
                                                >
                                                <div class="switch"></div>
                                            </div>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile *ngIf="netflowEnabled && !hideNetflowFields" [colspan]="9">
                                        <div class="label netflowFieldLabel">Collector</div>
                                    </mat-grid-tile>
                                    <mat-grid-tile *ngIf="netflowEnabled && !hideNetflowFields" [colspan]="11">
                                        <select
                                            [(ngModel)]="exportModel.collectorId"
                                            [disabled]="!canUpdateExports"
                                            [ngClass]="{ error: errorCollector }"
                                            class="collectors netflowField"
                                            id="CollectorList"
                                            placeholder="Select a Netflow Collector"
                                        >
                                            <option value="">Select A Netflow Collector</option>
                                            <option
                                                *ngFor="let collector of collectors | sortby: 'name'"
                                                [value]="collector.id"
                                            >
                                                {{ collector.name }}
                                            </option>
                                        </select>
                                    </mat-grid-tile>
                                    <mat-grid-tile *ngIf="netflowEnabled && !hideNetflowFields" [colspan]="9">
                                        <div class="label netflowFieldLabel">Update Frequency (sec)</div>
                                    </mat-grid-tile>
                                    <mat-grid-tile *ngIf="netflowEnabled && !hideNetflowFields" [colspan]="11">
                                        <input
                                            [(ngModel)]="exportModel.exportIntervalSeconds"
                                            [disabled]="!canUpdateExports"
                                            [ngClass]="{ error: errorFrequency }"
                                            class="netflowField"
                                            id="EditFrequency"
                                            max="300"
                                            min="1"
                                            placeholder="the frequency the collector updates in seconds"
                                            type="number"
                                        />
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isRegistered && !isHAGateway" class="row">
            <div class="midSized">
                <div [hidden]="hideRegKey" class="half" id="RegKeyArea">
                    <div *ngIf="!zitiEnabledService.zitiPure">
                        <div class="label row">Gateway Registration Key<span class="note">Click to Copy</span></div>
                        <div class="row">
                            <input
                                (click)="copy()"
                                [(ngModel)]="model['registrationKey']"
                                class="RegKey"
                                id="EditRegKey"
                                readonly
                                type="text"
                            />
                        </div>
                    </div>
                    <div *ngIf="zitiEnabledService.zitiPure">
                        <div class="label row">Gateway Registration Key<span class="note">Tap To Download</span></div>
                        <div class="row">
                            <div (click)="downloadJWT()" class="downloadButton">
                                <div class="DownloadKey"></div>
                                <div>DOWNLOAD KEY</div>
                                <div class="TapToDownload"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="showCloudFormationButton" class="half padLeft" id="CFButtonArea">
                    <div class="label row">
                        Launch AWS Gateway<span class="note"
                            ><a href="https://netfoundry.zendesk.com/hc/en-us/articles/360020282711" target="_blank"
                                >Script Help?</a
                            ></span
                        >
                    </div>
                    <div class="row">
                        <div (click)="openCloudFormationLink()" class="AwsButton" id="LaunchCfGateway">
                            <span class="hideSmall">Launch Gateway using<br />cloud formation script</span>
                            <span class="showSmall">Launch<br />Gateway</span>
                            <div class="click"></div>
                        </div>
                    </div>
                </div>

                <div *ngIf="showAzureFormationButton" class="half padLeft" id="CFAZButtonArea">
                    <div class="label row">Launch Azure Gateway<span class="note">Tap To Open Azure</span></div>
                    <div class="row">
                        <div
                            (click)="!disableButton && !disableAzureFormationButton && openAzureFormationLink()"
                            [ngClass]="{
                                disableLaunch: !disableButton && disableAzureFormationButton,
                                disable: disableButton
                            }"
                            class="AwsButton Azure"
                            id="LaunchCfAzGateway"
                        >
                            <span class="hideSmall">Deploy to<br />Microsoft Azure</span>
                            <span class="showSmall">Deploy<br />Gateway</span>
                            <div class="click"></div>
                        </div>
                        <div *ngIf="!disableButton && disableAzureFormationButton" class="disableLaunchNote">
                            TThis action requires access to Azure Stack Local Portal
                        </div>
                        <!-- TODO Delete -->
                        <div *ngIf="disableButton" class="disablebuttonote">This action is currently unavailable</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isHAGateway && !isAutoScale" class="row">
            <div *ngFor="let gateway of haGateways; let index = index" class="midSized">
                <div [hidden]="!displayRegistration(gateway)" class="half" id="RegKeyArea">
                    <div class="label row">
                        {{ gateway.name }} Registration Key<span class="note">Click to Copy</span>
                    </div>
                    <div class="row">
                        <input
                            (click)="copy(gateway)"
                            [(ngModel)]="gateway['registrationKey']"
                            class="RegKey"
                            id="EditRegKey_{{ gateway.id }}"
                            readonly
                            type="text"
                        />
                    </div>
                </div>

                <div
                    *ngIf="showCloudFormationButton && displayRegistration(gateway)"
                    class="half padLeft"
                    id="CFButtonArea"
                >
                    <div class="label row">
                        Launch {{ gateway.endpointProtectionRole
                        }}<span class="note"
                            ><a href="https://netfoundry.zendesk.com/hc/en-us/articles/360020282711" target="_blank"
                                >Script Help</a
                            ></span
                        >
                    </div>
                    <div class="row">
                        <div
                            (click)="openCloudFormationLink(gateway)"
                            class="AwsButton"
                            id="LaunchCfGateway_{{ gateway.id }}"
                        >
                            <span class="hideSmall">Launch Gateway using<br />cloud formation script</span>
                            <span class="showSmall">Launch<br />Gateway</span>
                            <div class="click"></div>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="showAzureFormationButton && displayRegistration(gateway)"
                    class="half padLeft"
                    id="CFAZButtonArea"
                >
                    <div class="label row">
                        Launch {{ gateway.endpointProtectionRole }}<span class="note">Tap To Open Azure</span>
                    </div>
                    <div class="row">
                        <div
                            (click)="!disableButton && !disableAzureFormationButton && openAzureFormationLink()"
                            [ngClass]="{
                                disableLaunch: !disableButton && disableAzureFormationButton,
                                disable: disableButton
                            }"
                            class="AwsButton Azure"
                            id="LaunchCfAzGateway_{{ gateway.id }}"
                        >
                            <span class="hideSmall">Deploy to<br />Microsoft Azure</span>
                            <span class="showSmall">Deploy<br />Gateway</span>
                            <div class="click"></div>
                        </div>
                        <div *ngIf="!disableButton && disableAzureFormationButton" class="disableLaunchNote">
                            This action requires access to Azure Stack Local Portal
                        </div>
                        <!-- TODO Delete -->
                        <div *ngIf="disableButton" class="disablebuttonote">This action is currently unavailable</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isAutoScale" class="row">
            <div class="midSized">
                <div *ngIf="showCloudFormationButton && displayAutoScalingRegistration(model)" id="CFButtonArea">
                    <div class="label row">
                        Launch Auto Scaling Gateways<span class="note"
                            ><a href="https://netfoundry.zendesk.com/hc/en-us/articles/360020282711" target="_blank"
                                >Script Help</a
                            ></span
                        >
                    </div>
                    <div class="row">
                        <div
                            (click)="openAutoScaleCloudFormationLink(model)"
                            class="AwsButton"
                            id="LaunchCfGateway_{{ model.id }}"
                        >
                            <span class="hideSmall">Launch Gateway using<br />cloud formation script</span>
                            <span class="showSmall">Launch<br />Gateway</span>
                            <div class="click"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="midSized">
                <div class="row buttonRow right" style="margin-top: 0.625rem; margin-bottom: 1.875rem">
                    <button
                        (click)="confirmDelete()"
                        *ngIf="
                            (!isAzureGateway && !isHAGateway && canDeleteGateway) ||
                            (isAzureGateway && canDeleteAzureSite) ||
                            (isHAGateway && canDeleteCluster)
                        "
                        class="save alert confirm"
                        id="DeleteEditGatewayButton"
                        style="margin-right: 1.25rem"
                    >
                        Delete Gateway
                    </button>
                    <button
                        (click)="save()"
                        *ngIf="((!isHAGateway && canUpdateGateway) || (isHAGateway && canUpdateCluster)) && !processing"
                        class="save"
                        id="SaveEditGatewayButton"
                    >
                        Update
                    </button>
                    <button *ngIf="processing" class="save confirm" disabled>
                        Saving <span class="spinner"></span>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isRegistered && !isAutoScale">
            <div class="row">
                <div class="fullSized">
                    <div class="splitGrid gapped">
                        <div>
                            <div class="cardStack full" style="height: 24.375rem">
                                <!-- Mop Event Timeline -->
                                <app-mop-timeline
                                    [componentIdList]="componentIdList"
                                    [componentId]="componentId"
                                    [endpointNameFilter]="endpointNameFilter"
                                    [networkId]="currentNetworkId"
                                    [resourceId]="resourceId"
                                    [startTime]="startTime"
                                ></app-mop-timeline>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div *ngIf="model.name" class="cardStack full" style="height: 24.375rem">
                                    <div class="title">
                                        <!-- Date Filter -->
                                        <app-date-filters
                                            (endChange)="setEndTime($event)"
                                            (startChange)="setStartTime($event)"
                                            [currentTimeFilter]="currentTimeFilter"
                                        ></app-date-filters>

                                        <div class="gatewayUtilizationTitle">Gateway Utilization</div>

                                        <app-utilization-filters
                                            (directionChange)="setDirectionFilter($event)"
                                            [showUsageEndpoint]="false"
                                        ></app-utilization-filters>
                                    </div>

                                    <!-- Graph -->
                                    <app-utilization-timeline
                                        [endTime]="endTime"
                                        [endpointType]="endpointFilter"
                                        [networkDataTypes]="directionFilter"
                                        [networkId]="currentNetworkId"
                                        [resourceIdList]="resourceIdList"
                                        [resourceId]="componentId"
                                        [startTime]="startTime"
                                    ></app-utilization-timeline>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row marginBottom">
            <div class="fullSized">
                <div class="thirdGrid gapped">
                    <app-itemlist
                        (addEvent)="addToGroups($event)"
                        (changed)="refreshLists($event)"
                        (closeEvent)="close()"
                        (deleteEvent)="removeFromGroups($event)"
                        *ngIf="!isHAGateway && authorizationService.canListEndpointGroups()"
                        [allItems]="allGroups"
                        [checkAuth]="true"
                        [formComponent]="groupFormComponent"
                        [hasToggle]="true"
                        [item]="groups"
                        [totalAssociated]="groups.items.length"
                        id="GroupItemList"
                    >
                    </app-itemlist>

                    <app-itemlist
                        (addEvent)="addToAppWans($event)"
                        (changed)="refreshLists($event)"
                        (closeEvent)="close()"
                        (deleteEvent)="removeFromAppWans($event)"
                        *ngIf="!isHAGateway && authorizationService.canListAppWans()"
                        [allItems]="allAppWans"
                        [checkAuth]="true"
                        [formComponent]="appwanFormComponent"
                        [hasToggle]="true"
                        [item]="appwans"
                        [totalAssociated]="appwans.items.length"
                        id="AppWanItemList"
                    >
                    </app-itemlist>

                    <app-itemlist
                        (changed)="refreshLists($event)"
                        (closeEvent)="close()"
                        *ngIf="!isZitiGateway && authorizationService.canListServices()"
                        [checkAuth]="true"
                        [formComponent]="serviceFormComponent"
                        [hasToggle]="false"
                        [isCluster]="isHAGateway"
                        [item]="services"
                        [totalAssociated]="services.items.length"
                        id="ServiceItemList"
                    >
                    </app-itemlist>
                </div>
            </div>
        </div>
    </div>
    <div (click)="close()" class="buttonBall close" id="EscGatewayButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div
        (click)="share()"
        [hidden]="hideRegKey || !canShareEndpoint || isAutoScale"
        class="buttonBall share"
        id="ShareEditGatewayButton"
    >
        <div class="buttonLabel">SHARE</div>
    </div>
    <div *ngIf="isHAGateway && haGateways.length > 0" id="HAStatus">
        <div *ngFor="let haGateway of haGateways" class="row">
            <app-resource-status
                [item]="haGateway"
                [resourceType]="'gateway'"
                [showBubble]="true"
            ></app-resource-status>
        </div>
    </div>
    <div *ngIf="!isHAGateway" id="GatewayStatus">
        <div class="graphic">
            <svg
                id="Layer_1"
                style="enable-background: new 0 0 72 72"
                version="1.1"
                viewBox="0 0 72 72"
                x="0rem"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                y="0rem"
            >
                <style type="text/css"></style>
                <g id="IoT_4_">
                    <g id="XMLID_23_">
                        <path
                            class="st0"
                            d="M41.3,66.2v-5.4c2.8-0.6,5.4-1.7,7.8-3.2l3.8,3.8c1.4,1.4,3.6,1.4,4.9,0l2.9-2.9
					   c1.4-1.4,1.4-3.6,0-4.9l-3.8-3.8c1.5-2.4,2.6-5,3.2-7.8h5.4c1.9,0,3.5-1.6,3.5-3.5v-4.1c0-1.9-1.6-3.5-3.5-3.5h-5.4
					   c-0.6-2.8-1.7-5.4-3.2-7.7l3.8-3.8c1.4-1.4,1.4-3.6,0-5l-2.7-3c-1.4-1.4-3.6-1.4-4.9,0l-3.8,3.8c-2.4-1.5-5-2.6-7.8-3.3V6.5
					   C41.5,4.6,39.9,3,38,3h-4c-1.9,0-3.5,1.6-3.5,3.5v5.4c-2.8,0.6-5.4,1.7-7.8,3.2l-3.8-3.9c-1.4-1.4-3.6-1.4-4.9,0l-3,2.9
					   c-1.4,1.4-1.4,3.6,0,5l3.8,3.8c-1.5,2.4-2.6,5-3.3,7.8H6.1c-1.9,0-3.5,1.6-3.5,3.5v4.1c0,1.9,1.6,3.5,3.5,3.5h5.4
					   c0.6,2.8,1.7,5.5,3.2,7.8l-3.8,3.8c-1.4,1.4-1.4,3.6,0,5l2.9,2.9c1.4,1.4,3.6,1.4,4.9,0l3.8-3.8c2.3,1.5,5,2.6,7.8,3.3v5.4
					   c0,1.9,1.6,3.5,3.5,3.5h4C39.7,69.6,41.3,68.1,41.3,66.2z"
                            id="XMLID_24_"
                        />
                    </g>
                </g>
                <path
                    class="st1"
                    d="M51.4,34.2c1.3,10-7.2,18.4-17.2,17.2C27.4,50.5,21.9,45,21,38.2c-1.3-10,7.2-18.4,17.2-17.2
			   C45.1,21.8,50.6,27.3,51.4,34.2z"
                    id="XMLID_22_"
                />
                <path
                    [ngClass]="statusClass()"
                    d="M52.6,34c1.4,10.8-7.7,19.9-18.5,18.5c-7.4-0.9-13.3-6.9-14.2-14.2
			   c-1.4-10.8,7.7-19.9,18.5-18.5C45.8,20.7,51.7,26.6,52.6,34z"
                    id="XMLID_21_"
                />
            </svg>
        </div>
        <span class="b{{ model['status'] }} gateway" id="status"></span>Gateway
        <span id="statusText"></span>
    </div>
</div>
