<app-loader [isLoading]="isLoading"></app-loader>
<div class="fullModal opened" id="AddVWanModal">
    <div class="fullRow">
        <div [hidden]="isEditing" class="title">Create A New Azure Virtual WAN Site</div>
        <div [hidden]="isAdding" class="title">Edit Your Azure Virtual WAN Site</div>
        <div class="subtitle">Enter your site attributes</div>
        <div class="sized">
            <div class="label row">
                Azure Virtual WAN Site Name<span [ngClass]="{ error: errors['name'] }" class="note">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errors['name'] }"
                    class="restrictNormal"
                    id="EditSiteName"
                    maxlength="64"
                    placeholder="What would you like to call this site?"
                    type="text"
                />
            </div>

            <div class="label row">
                Gateway<span [hidden]="!errors['endpointId']" class="error">Required</span
                ><span [hidden]="errors['endpointId']" class="note">Required</span>
            </div>
            <div class="row">
                <select
                    [(ngModel)]="model.endpointId"
                    [ngClass]="{ error: errors['endpointId'] }"
                    id="GatewayList"
                    placeholder="Select a Gateway"
                >
                    <option value="">Select a Gateway</option>
                    <option *ngFor="let gateway of gateways | sortby: 'name'" [value]="gateway.getId()">
                        {{ gateway.name }}
                    </option>
                </select>
            </div>

            <div class="label row">
                Azure Resource Group Name<span [ngClass]="{ error: errors['azureResourceGroupName'] }" class="note"
                    >Required - Must be Created in Azure</span
                >
            </div>
            <div class="row">
                <select
                    [(ngModel)]="model.azureResourceGroupName"
                    [ngClass]="{ error: errors['azureResourceGroupName'] }"
                    id="ResourceGroupList"
                >
                    <option value="">Select a Resource Group</option>
                    <option *ngFor="let group of azureResourceGroups | sortby: 'name'" [value]="group.getName()">
                        {{ group.name }}
                    </option>
                </select>
            </div>

            <div class="label row">
                Azure Virtual WAN<span [ngClass]="{ error: errors['azureVirtualWanId'] }" class="note">Required</span>
            </div>
            <div class="row">
                <select
                    [(ngModel)]="model.azureVirtualWanId"
                    [ngClass]="{ error: errors['azureVirtualWanId'] }"
                    id="VirtualWanList"
                >
                    <option value="">Select a Virtual WAN</option>
                    <option *ngFor="let wan of azureVirtualWans | sortby: 'name'" [value]="wan.getAzureId()">
                        {{ wan.name }}
                    </option>
                </select>
            </div>

            <!-- Location -->
            <div class="label row">
                Azure Location<span [ngClass]="{ error: errors['dataCenterId'] }" class="note">Required</span>
            </div>
            <div class="row">
                <select
                    [(ngModel)]="model.dataCenterId"
                    [ngClass]="{ error: errors['dataCenterId'] }"
                    id="VirtualLocationList"
                >
                    <option value="">Select a Location</option>
                    <option *ngFor="let dc of dataCenters | sortby: 'name'" [value]="dc.getId()">
                        {{ dc.name }}
                    </option>
                </select>
            </div>

            <div class="label row">
                Public IP Address<span [ngClass]="{ error: errors['publicIpAddress'] }" class="note">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.publicIpAddress"
                    [ngClass]="{ error: errors['publicIpAddress'] }"
                    class="restrictNormal"
                    id="PublicIpAddress"
                    placeholder="Public IP Address"
                    type="text"
                />
            </div>

            <div class="label row">
                Private Address Space<span [ngClass]="{ error: errors['privateAddressSpace'] }" class="note"
                    >Required</span
                >
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model['advertisedPrefixes']"
                    [ngClass]="{ error: errors['privateAddressSpace'] }"
                    class="restrictNormal"
                    id="PrivateAddressSpace"
                    placeholder="Private Address Space"
                    type="text"
                />
            </div>

            <!--<div class="row">-->
            <!--<div class="label curved">Add Sites</div>-->
            <!--<div class="row" style="padding-bottom: 1.25rem;">-->
            <!--<div id="ClearSites" class="clearFilter" data-id="SearchSites" (click)="clearFilter()"></div>-->
            <!--<input id="SearchSites" class="curved" placeholder="Search for a Site" [(ngModel)]="searchString"/>-->
            <!--</div>-->
            <!--</div>-->
            <!---->
            <!---->
            <!--<div class="row">-->
            <!--<div class="leftList">-->
            <!--<div class="subcontent center">Available Virtual WANs</div>-->
            <!--<div id="AllWans" class="formList scroll" data-to="SelectedWans" style="height: 25rem;">-->
            <!--<div class="listItem" *ngFor="let wan of selectedWans | sortby: 'name'| textSearch: 'name' : searchString; let index = index" [attr.data-index]="index" id="Wans_{{wan.id}}" (click)="moveTo(wan, 'wan')" [hidden]="isAssigned(wan,'wan')">-->
            <!--{{ wan.name }}-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="moveButtons">-->
            <!--<div id="SelectedWans_Arrow" class="arrow moveLeft moveFor" data-to="AllWans" data-from="SelectedServices" (click)="removeAllWans()"></div>-->
            <!--<div id="AllWans_Arrow" class="arrow moveRight moveFor" data-to="SelectedWans" data-from="AllServices" (click)="addAllWans()"></div>-->
            <!--</div>-->
            <!--<div class="rightList">-->
            <!--<div class="subcontent center">Selected Virtual WANs</div>-->
            <!--<div id="SelectedWans" class="formList scroll" data-to="AllWans" style="height: 25rem;">-->
            <!--<div class="listItem" *ngFor="let wan of availableWans | sortby: 'name'; let index = index" [attr.data-index]="index" id="WansSelected_{{wan.id}}" (click)="moveFrom(wan, 'wan')" [hidden]="!isAssigned(wan,'wan')">-->
            <!--{{ wan.name }}-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!---->
            <!--</div>-->

            <div class="row right" style="margin-top: 1.25rem">
                <div (click)="save()" class="button" id="SaveSiteButton">Save</div>
            </div>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="CloseSiteButton">
        <div class="buttonLabel">ESC</div>
    </div>
</div>
