<div class="cardStack full" style="height: 12.5rem">
    <div class="title">
        <div class="chop">
            {{ billingEnabledService.billingEnabled ? 'Billing ' : '' }} Usage -
            {{ networkFilter.name ? networkFilter.name : 'All Networks' }}
        </div>
        <div class="nameValueGrid center desktop">
            <div class="nameLabel">Current Cycle</div>
            <div class="nameValue">
                {{ cycleStart.format(dateFormat) + ' - ' + cycleEnd.format(dateFormat) }}
            </div>
        </div>
        <div *ngIf="billingEnabledService.billingEnabled" class="nameValueGrid right">
            <div class="nameLabel">Next&nbsp;Bill&nbsp;Date</div>
            <div class="nameValue">{{ billDate.format(dateFormat) }}</div>
        </div>
    </div>
    <div class="row" style="top: 4.0625rem">
        <app-loader [isLoading]="networkLoading"></app-loader>
        <div [hidden]="!noData" class="noDataBilling">No Data</div>
        <div *ngIf="!networkLoading && !canReadElasticSearch" class="noDataBilling">No Permissions</div>
        <div [hidden]="!canReadElasticSearch || noData || networkLoading">
            <div class="progressGrid" style="top: -3.75rem">
                <div class="nameValueGrid stacked">
                    <div class="nameLabel">{{ daysLeft }} days</div>
                    <div class="nameValue">remaining</div>
                </div>

                <div class="progressArea">
                    <div class="progressEncapsolate">
                        <div [ngStyle]="{ width: percentage + '%' }" class="progressBar">
                            <div
                                *ngFor="let region of regionUtilization; let index = index"
                                [ngClass]="'highcharts-color-' + index"
                                [ngStyle]="{ width: region.percentage + '%' }"
                                class="progressBar"
                                id="{{ prettyRegionProgressBarId(region) }}"
                                matTooltip="{{ region.label }}
                   {{ region.percentage }}%
                   {{ region.converted }}"
                            ></div>
                        </div>

                        <div [ngStyle]="{ left: 'calc(' + percentage + '% - 2.375rem)' }" class="progressMarker">
                            <div class="markerLine"></div>
                            <div class="nameValueGrid stacked" id="CurrentTotalUsage">
                                <div class="nameLabel">
                                    {{ totalUtilization | tobytes: true }}
                                </div>
                                <div class="nameValue">current</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="nameValueGrid stacked" id="ProjectedUsage">
                    <div class="nameLabel">{{ projected | tobytes: true }}</div>
                    <div class="nameValue">projected</div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <div id="usageStamp">Usage as of {{ utilizationAsOfTime }}</div>
        <span *ngIf="billingEnabledService.billingEnabled">
            <a *ngIf="showDashLink" [routerLink]="Billing" class="desktop" id="BillingDashLink"
                >View my Billing Dashboard</a
            >
        </span>
    </div>
</div>
