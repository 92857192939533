<app-action-button
    (addEvent)="create()"
    (deleteEvent)="openDeleteSelectedConfirm()"
    [hidden]="
        (!isDeleteAction && !authorizationService.canCreateNetworks()) ||
        (isDeleteAction && selectedNetworkIds.length > 1)
    "
    [isDeleteAction]="isDeleteAction"
>
</app-action-button>

<app-loading-indicator
    *ngIf="isLoading"
    [isLoading]="isLoading"
    class="load-indicator"
    id="networksPage"
></app-loading-indicator>

<div class="area data-table" id="ItemsArea">
    <app-noitems
        (clickEmit)="create()"
        [image]="'No_Networks'"
        [isEmpty]="showNoData"
        [typeName]="'networks'"
        [hasAdd]="hasAdd"
    ></app-noitems>

    <app-data-table2
        (actionRequested)="actionRequested($event)"
        (filterChanged)="filterChanged($event)"
        (gridReady)="gridReady($event)"
        *ngIf="filterApplied || (items.length > 0 && !isLoading) || filterHasChanged || changedPage"
        [columnDefinitions]="columnDefs"
        [columnFilters]="columnFilters"
        [rowData]="displayedItems"
        [tableId]="'network'"
    ></app-data-table2>
</div>
