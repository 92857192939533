<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddGatewayModal">
    <app-loader [isLoading]="isLoading"></app-loader>

    <div class="title" style="filter: brightness(50%)">Create Sites in Bulk</div>
    <div class="subtitle">Select Your File To Upload</div>
    <div class="sized">
        <!-- Azure BULK Add -->
        <div class="subtext row">
            Creating Azure Virtual WAN Sites in bulk is easy. Just download our template below, fill in your site
            information and upload your file. We'll create them in the background and let you know when they’re ready.
        </div>
        <div class="label row">Download A Template</div>
        <div (click)="download()" class="graphicButton">
            <div>Download An<br />Example File</div>
            <div class="iconCSV"></div>
        </div>

        <div class="label row">Select And Upload A File</div>
        <div (onFileDrop)="fileDrop()" [uploader]="uploader" class="uploadArea" ng2FileDrop>
            <div [hidden]="fileIsReady" class="label">Drag and drop a CSV file</div>
            <div [hidden]="fileIsReady" class="sublabel">
                or
                <div (click)="showDialog()" class="linkButton" id="AzureFileUpload">
                    Select a file from your computer
                </div>
                <form [formGroup]="form">
                    <input
                        #fileInput
                        (change)="onFileChange($event)"
                        id="FileSelect"
                        name="file"
                        style="display: none"
                        type="file"
                    />
                </form>
            </div>
            <div [hidden]="!fileIsReady" class="label">Click create to import CSV</div>
        </div>
        <div class="row buttonRow right" style="margin-top: 1.25rem">
            <button (click)="save()" *ngIf="!processing" class="save" id="SaveGatewayButton">Create</button>
            <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
        </div>
    </div>

    <div (click)="hideForm()" class="buttonBall close" id="CloseGatewayButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div class="private">
        <div (click)="goBack()" class="buttonBall back" id="BackGatewayButton">
            <div class="buttonLabel">BACK</div>
        </div>
    </div>
</div>
