<div class="sized" id="usersinfoPage">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div *ngIf="!isLoading">
        <div class="area" id="ProfileArea">
            <div class="row padTop">
                <div [ngStyle]="{ 'background-image': 'url(' + photo + ')' }" class="editIcon profileIcon"></div>
                <div class="editMe middle"></div>
            </div>
            <div class="row">
                <div class="half">
                    <div class="label row">
                        First Name<span [ngClass]="{ error: errorFirstName, note: !errorFirstName }"></span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="user.firstName"
                            [ngClass]="{ error: errorFirstName }"
                            class="restrictNormal"
                            id="EditFirstName"
                            type="text"
                        />
                    </div>
                </div>
                <div class="half padLeft">
                    <div class="label row">
                        Last Name<span [ngClass]="{ error: errorLastName, note: !errorLastName }"></span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="user.lastName"
                            [ngClass]="{ error: errorLastName }"
                            class="restrictNormal"
                            id="EditLastName"
                            type="text"
                        />
                    </div>
                </div>
            </div>
            <div class="label row">Nick Name</div>
            <div class="row">
                <input
                    [(ngModel)]="nickname"
                    data-enter="UpdateButton"
                    disabled
                    id="EditName"
                    placeholder="Enter your first name"
                    readonly
                    type="text"
                />
            </div>
            <div class="label row">EMail Address</div>
            <div class="row">
                <input
                    [(ngModel)]="email"
                    data-enter="UpdateButton"
                    data-private
                    disabled
                    id="EditEMailAddress"
                    placeholder="Enter your email address"
                    readonly
                    type="text"
                />
            </div>
            <div
                (click)="resetPassword()"
                [ngClass]="{ hidden: !canReset }"
                class="save confirm alert"
                id="ResetPassword"
                style="margin-top: 1.5625rem"
            >
                Reset Password
            </div>
            <div class="row">
                <div class="midSized"></div>
            </div>
        </div>

        <div *ngIf="showFeatureToggle" class="label row">
            <div>Experimental Features</div>
        </div>
        <div *ngIf="showFeatureToggle" class="row">
            <div class="switchGrid">
                <div class="toggleDiv">
                    <div
                        (click)="toggleExperimentalFeatures()"
                        [ngClass]="{ on: featureService.experimentalFeatures }"
                        class="toggle"
                    >
                        <span [hidden]="!featureService.experimentalFeatures">ON&nbsp;&nbsp;&nbsp;</span>
                        <span [hidden]="featureService.experimentalFeatures">&nbsp;&nbsp;&nbsp;OFF</span>
                        <div class="switch"></div>
                    </div>
                </div>
                <div>Click here to enable experimental console features</div>
            </div>
            <div class="row buttonRow right" style="margin-top: 0.625rem; margin-bottom: 1.875rem">
                <button (click)="save()" *ngIf="!processing" class="save" id="SaveEditUserButton">Update</button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
    </div>
</div>
