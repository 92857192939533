<div>
    <div *ngIf="!isLoading && !noData">
        <app-highcharts-generic
            [chartheight]="height"
            [options]="utilization_options"
            [series]="utilization_series"
        ></app-highcharts-generic>
    </div>
</div>

<div *ngIf="!isLoading && noData" class="noDataZiti">No Data</div>
<div *ngIf="isLoading" style="margin-top: 9.375rem">
    <app-loader [isLoading]="isLoading"></app-loader>
</div>
