<div [ngClass]="{ open: svc.open, hide: hideNav }" class="side-navigator-container" (mouseenter)="showExpandCollapse($event)" (mouseleave)="hideExpandCollapse($event)">
    <div class="side-navigator-header-container">
        <div *ngIf="false && (svc.area === 'Network' || svc.area === 'ZAC' || svc.area === 'Browzer')" class="networks">
            <div (click)="toggleList($event)" class="currentNetwork" id="currentNetwork">
                {{ svc.currentNetwork.name }}
            </div>
            <div [ngClass]="{ opened: isOpened }" class="shadow" id="Networks">
                <div class="row">
                    <div (click)="toggleList($event)" class="currentNetwork">
                        {{ svc.currentNetwork.name }}
                    </div>
                </div>
                <div id="ListArea">
                    <div class="list-area search row">
                        <input
                            (click)="stopProp($event)"
                            [(ngModel)]="filterString"
                            id="SearchNetworks"
                            placeholder="Type to Filter"
                            type="text"
                        />
                    </div>
                    <div class="headerNetworkList row">
                        <div
                            (click)="setNetwork(networkModel)"
                            *ngFor="let networkModel of svc.allNetworks | tableSearch: filterString | sortby: 'name'"
                            class="selectNetwork row"
                        >
                            <div class="status s{{ networkModel.status }}" id="nStatList{{ networkModel.id }}">
                                <div class="anim5"></div>
                            </div>
                            {{ networkModel.name }}
                        </div>

                        <div
                            (click)="svc.addNetwork()"
                            *ngIf="authorizationService.canCreateNetworks()"
                            class="item row"
                            id="AddNetworkButton"
                        >
                            <div class="icon append icon-BigSoftPlus"></div>
                            <div class="content">Add a New Network</div>
                        </div>
                    </div>
                    <!--          <div *ngIf="!featureService.experimentalFeatures" id="ManageNetworkButton" class="navItem" routerLink="/networks">Manage Networks</div>-->
                    <div class="navItem" id="ManageNetworkButton" routerLink="/networks">Manage Networks</div>
                </div>
            </div>
        </div>
        <lib-item-selector
          *ngIf="svc.area === 'Network' || svc.area === 'ZAC' || svc.area === 'Browzer'"
          [currentItem]="svc.currentNetwork"
          [isOpened]="isOpened"
          [items]="svc.allNetworks"
          [addNewItemLabel]="'Add a New Network'"
          [manageItemsLabel]="'Manage Networks'"
          [manageItemsLink]="'/networks'"
          [showAddNewItem]="authorizationService.canCreateNetworks()"
          (currentItemChange)="setNetwork($event)"
          (addNewItem)="svc.addNetwork()"
          [(filterString)]="filterString"
        >
        </lib-item-selector>
        <div (clickOutside)="closeList()" *ngIf="showOrgPicker" class="tenantArea">
            <div class="tenants">
                <div (click)="toggleList($event)" class="currentTenant" id="currentTenant">
                    {{ svc.currentTenant.name }}
                </div>
                <div [ngClass]="{ opened: isOpened }" class="shadow" id="Tenants">
                    <div class="row">
                        <div (click)="toggleList($event)" class="currentTenant">
                            {{ svc.currentTenant.name }}
                        </div>
                    </div>
                    <div id="ListArea">
                        <div class="list-area search row">
                            <input
                                (click)="stopProp($event)"
                                [(ngModel)]="filterString"
                                id="SearchTenants"
                                placeholder="Type to Filter"
                                type="text"
                            />
                        </div>
                        <div class="headerTenantList row">
                            <div
                                (click)="setTenant(tenantModel)"
                                *ngFor="let tenantModel of svc.tenants | tableSearch: filterString | sortby: 'name'"
                                [ngClass]="{ selected: svc.currentTenant.id === tenantModel.id }"
                                class="selectNetwork row"
                            >
                                <div class="orgStatus s300" id="nStatList{{ tenantModel.id }}">
                                    <div class="anim5"></div>
                                </div>
                                {{ tenantModel.name }}
                            </div>
                            <div
                                (click)="svc.addTenant()"
                                *ngIf="authorizationService.canCreateTenant()"
                                class="item row"
                                id="AddTenantButton"
                            >
                                <div class="icon append icon-BigSoftPlus"></div>
                                <div class="content">Add a New Organization</div>
                            </div>
                        </div>
                        <div [routerLink]="Organizations" class="navItem" id="ManageTenantsButtons">
                            Manage Organizations
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!hideTitle" class="title">{{ svc.currentNav.title }}</div>
    </div>
    <div cdkScrollable class="side-navigator-menu-container" [ngClass]="{'hide-menu-nav-bar': hideMenuNavBar}">
        <div *ngFor="let group of svc.currentNav.groups" class="side-navigator-menu-group">
            <span class="side-navigator-menu-group-label">{{ group.label }}</span>
            <div class="side-navigator-header-buffer" *ngIf="group.headerBuffer"></div>
            <div *ngFor="let item of group.menuItems" class="{{item.class}}">
                <div
                    *ngIf="
                    (!(item.mopV7Only && featureService.isCloudZiti)) &&
                    (item.route !== networkControllerURL || authorizationService.canUpdateNetwork(currentNetwork.id)) &&
                        (item.route !== browZerURL ||
                            (featureService.powerUserEnabled && svc.networkCanSupportBrowZer) ||
                            featureService.isCloudZiti) &&
                        ((!item.enterpriseOnly && !item.selfServiceOnly) ||
                            (item.enterpriseOnly && enterpriseBilling) ||
                            (item.selfServiceOnly && !enterpriseBilling)) &&
                                (!hideMenuItem(item))
                                && (item.route !== cloudzitiBillingURL)
                    "
                    class="side-navigator-menu-item {{ item.className }}"
                    (click)="doNavAction(item)"
                    [ngClass]="{
                        selected: navItemSelected(item),
                        disabled: svc.isNavItemDisabled(item) || disableNavigation
                    }"
                    [title]="svc.disableMainNav && item.requiresProvision ? disabledToolTip : ''"
                >
                    <div class="side-navigator-menu-item-icon">
                        <div class="image" class="{{ item.iconClass }}"></div>
                        <span *ngIf="item.badgeCount" class="counterBadge">{{ item.badgeCount }}</span>
                    </div>
                    <span class="side-navigator-menu-item-label">
                        {{ item.label }}
                        <span class="side-navigator-menu-item-sublabel">{{ item.sublabel }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="side-navigator-footer-container">
        <div
            *ngIf="brandingModeService.logosAvailable"
            [ngClass]="{ dark: brandingService.darkModeEnabled }"
            class="navlogo"
        ></div>
        <div class="side-navigator-version-container">
          <div *ngIf="currentNetwork.id" class="networkInfo" id="NetworkVersion">
            Network Version: <span class="network-semver">{{ majorVersionNumber }}.{{ minorVersionNumber }}.{{ patchVersionNumber }}</span>
          </div>
          <div *ngIf="currentZitiEdgeDetails.zitiVersion" class="zitiInfo" id="ZitiVersion">
            Ziti Version: <span class="ziti-semver">{{ currentZitiEdgeDetails.zitiVersion }}</span>
          </div>
          <div
            (click)="toggleLock()"
            [ngClass]="{ locked: menuService.locked }"
            class="lock-icon"
            id="MenuLock"
            title="Lock Menu Open"
          ></div>
        </div>
    </div>
</div>
<div class="expand-collapse-hitbox-container" [ngClass]="{'expand-collapse-container-closed': !svc.open}" (mouseenter)="showExpandCollapse($event)" (mouseleave)="hideExpandCollapse($event)" *ngIf="featureService.isCloudZiti">
  <div class="expand-collapse-icon icon-prev" [ngClass]="{'expand-collapse-icon-visible': this.expandCollapseIconVisible}" (click)="collapseNav($event)"></div>
</div>
