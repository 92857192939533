import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { DownloadEntitiesComponent } from '@netfoundry-ui/feature/download-entities';
import { V2NetworkCreationFormComponent } from '@netfoundry-ui/feature/form/v2-network-creation-form';
import { NetworkInfoV2Component } from '@netfoundry-ui/feature/network-info-v2';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { NetworkSupportComponent } from '@netfoundry-ui/feature/views/modals';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT, Network, NetworkGroup, NetworkV2, Tenant } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    HTTP_CLIENT,
    LoggerService,
    NetworkGroupService,
    RefresherService,
    TokenService,
} from '@netfoundry-ui/shared/services';
import { NetworkUpgradeService } from '@netfoundry-ui/feature-network-upgrade';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { JsonViewComponent } from '@netfoundry-ui/feature/json-view';
import { MoveNetworkModalComponent } from '@netfoundry-ui/feature/move-network-modal';
import { NetworkRestoreModalComponent } from '@netfoundry-ui/feature/network-restore-modal';
import { RenameFormComponent } from '@netfoundry-ui/feature/form/rename-form';
import { PagedGetOption, Sort, SortOrder } from '@lagoshny/ngx-hateoas-client';
import { PublicCertConfirmModalComponent } from '@netfoundry-ui/feature/publiccert-confirm-modal';
import { ManageControllerConfigModalComponent } from '@netfoundry-ui/feature/manage-controller-config-modal';

@Component({
    selector: 'app-networks-combined',
    templateUrl: './networks-combined.component.html',
    styleUrls: ['./networks-combined.component.scss'],
})
export class NetworksCombinedComponent implements OnInit, OnDestroy {
    @Output() networkChange: EventEmitter<string> = new EventEmitter();

    gridObj;
    gridApi;
    typeName = 'Network';
    items: NetworkV2[] = [];
    displayedItems: NetworkV2[] = [];
    itemCount = 0;
    showList = false;
    isLoading = true;
    filterHasChanged: boolean;
    showNoData = false;
    filterApplied = false;
    changedPage: boolean;
    allToggled = false;
    sorting = 'name';
    ordering: SortOrder = 'ASC';
    filterString = '';
    dialogRef;
    page = 1;
    pageSize = this.environment.pageSize;
    totalElements = 0;
    currentTenant = new Tenant({});
    columnDefs;
    columnFilters: any = {
        name: '',
        region: '',
        status: '',
        id: '',
        productVersion: '',
    };
    openIndex = -1;
    public selectedNetworkIds = [];
    public hasSomeDeletePermission = true;
    canListNetworkGroups = false;
    provisionedAt;
    isDeleteAction = false;
    networkGroup = new NetworkGroup({});
    networkGroupMap = {};
    networkGroupFilter = false;
    selectedNetworkGroupName = 'Network Group';
    networkGroups = [];
    currentNetwork: NetworkV2;
    networksV7 = [];
    providers = [];
    upgradeableVersions: any[] = [];
    loadingProviders = true;
    relevantNetworkGroups = new Set<string>();
    groupHeaderParams = {
        filterType: 'SELECT',
        filterOptions: [
            { label: 'All', value: undefined },
            { label: 'Active', value: true },
            {
                label: 'Inactive',
                value: false,
            },
        ],
        columnFilters: this.columnFilters,
    };
    hasAdd = false;
    paramList: { size: number; sort: Sort } = {
        size: this.pageSize,
        sort: { name: 'ASC' },
    };
    private deleting = 0;
    private subscription = new Subscription();
    private setNetworkAfterDelete = false;
    private deletedNetworkIds = [];
    private inDeletingStatusCount = 0;
    private noDeleteAuthCount = 0;

    constructor(
        private logger: LoggerService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private cookieService: CookieService,
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute,
        public dialogForm: MatDialog,
        private refresher: RefresherService,
        public authorizationService: AuthorizationService,
        public filterService: TableFilterService,
        private networkGroupService: NetworkGroupService,
        private sortByPipe: SortbyPipe,
        private csvDownloadService: CsvDownloadService,
        private fileSaverService: FileSaverService,
        private tokenService: TokenService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        public featureService: FeatureService,
        public growlerService: GrowlerService,
        private networkUpgradeService: NetworkUpgradeService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.canListNetworkGroups = this.authorizationService.canListOrganizations();
        this.hasAdd = this.authorizationService.canCreateNetworks();
    }

    async ngOnInit() {
        this.filterService.setPageSize(this.pageSize);

        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.changedPage = false;
                if (this.page !== pageNum) {
                    this.changedPage = true;
                }
                this.page = pageNum;
                this.isLoading = true;
                if (this.changedPage) {
                    this.refresh();
                }
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.columnFilters.name = filterString;
                this.isfilterApplied();
                this.refresh();
                this.applyNetworkGroupFilter(this.networkGroupFilter);
            })
        );

        // network groups - create lookup table for names
        if (this.canListNetworkGroups) {
            this.networkGroupMap = {};
            await this.networkGroupService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    for (const item of result) {
                        const networkGroup = new NetworkGroup(item);
                        this.networkGroups.push(networkGroup);
                        this.networkGroupMap[networkGroup.id] = networkGroup.name;
                    }
                });

            this.networkGroups = this.sortByPipe.transform(this.networkGroups, 'name', 'asc');

            // subscribing to the network group id
            this.subscription.add(
                this.route.queryParams.subscribe((queryParams) => {
                    const networkGroupId = queryParams['networkGroupId'];

                    if (networkGroupId != null) {
                        this.setNetworkGroupNameFilter(networkGroupId);
                    }
                })
            );
        }

        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network as unknown as NetworkV2;
        });
        this.getHostInfo();
        this.initTableColumns();
        this.initNetworkList();
    }

    initNetworkList() {
      if (this.authorizationService.canListNetworks()) {
        this.refresh();
      } else {
        this.isLoading = false;
        this.showList = false;
      }
    }
    ngOnDestroy() {
        this.refresher.disableRefresh();
        this.subscription.unsubscribe();
        // this.networkSubscription.unsubscribe();
        this.filterService.reset();
    }

    create() {
        // this.dialogRef = this.dialogForm.open(WizardComponent, { data: {}, minHeight: '100%', minWidth: '100%', height: '100%', width: '100%' });
        this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
            data: {},
            id: 'V2NetworkDialog',
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });

        // refresh networks
        this.dialogRef.afterClosed().subscribe(() => {
            this.getAllNetworks();
        });
    }

    openDeleteSelectedConfirm() {
        let selectedItem;
        for (let i = 0; i < this.displayedItems.length; i++) {
            if (this.displayedItems[i].selected) {
                selectedItem = this.displayedItems[i];
            }
        }
        this.openConfirmDeleteDlg(selectedItem);
    }

    sort(sortBy, ordering) {
        if (this.sorting === sortBy) {
            this.ordering = ordering;
        } else {
            this.ordering = 'ASC';
            this.sorting = sortBy;
        }

        this.changedPage = true;

        const s: Sort = {};
        s[this.sorting] = this.ordering;
        this.paramList.sort = s;
        this.refresh();
    }

    actionRequested(request: any) {
        switch (request.action) {
            case 'toggleAll':
                this.toggleAll();
                break;
            case 'toggleItem':
                this.toggle(request.item);
                break;
            case 'delete':
                this.confirmDelete(request.item);
                break;
            case 'notifications':
                this.openNotification();
                break;
            case 'restart':
                this.restart(request.item);
                break;
            case 'resize':
                this.resize(request.item);
                break;
            case 'suspend':
                this.suspend(request.item);
                break;
            case 'rename':
                this.openRename(request.item);
                break;
            case 'resume':
                this.resume(request.item);
                break;
            case 'download':
                this.confirmExport(request.item);
                break;
            case 'firewall':
                this.openNetworkInfo(request.item);
                break;
            case 'json':
                this.openJson(request.item);
                break;
            case 'publicCert':
                this.openPublicCertModal(request.item);
                break;
            case 'upgrade':
                this.upgrade(request.item);
                break;
            case 'move':
                this.openMoveModal(request.item);
                break;
            case 'createBackup':
                this.createBackup(request.item);
                break;
            case 'restoreBackup':
                this.openRestoreModal(request.item);
                break;
            case 'downloadCsv':
                this.downloadCsv(request.item);
                break;
            case 'supportinfo':
                this.openSupportInfo(request.item);
                break;
            case 'configPort':
                this.openManageConfigPort(request.item);
                break;
            default:
                break;
        }
    }

    filterChanged(event) {
        if (event.columnId === 'name') {
            this.filterString = event.value;
        }
        if (event.columnId === 'networkGroupName') {
            this.setNetworkGroupNameFilter(event.value);
        }
        _.set(this.columnFilters, event.columnId, event.value);
        this.isLoading = true;
        this.isfilterApplied();
        this.shouldShowNoData();
        this.refresh();
    }

    gridReady(event) {
        this.gridApi = event.api;
        this.gridObj = event;
    }

    private downloadCsv(event: any) {
        // @TODO - fix for v7

        const columns = [
            'name',
            'status',
            'networkGroupName',
            'productFamily',
            'productVersion',
            'o365BreakoutCategory',
            'createdAt',
            'provisionedAt',
            'updatedAt',
        ];
        const filename = 'networks';
        const translateStatus = false;

        const csvItems = [];

        for (const item of this.displayedItems) {
            if (item.selected) {
                csvItems.push(item);
            }
        }

        this.toggleMenu(event, -2);
        this.csvDownloadService.download(filename, csvItems, columns, translateStatus);
    }

    private confirmExport(network: Network) {
        this.dialogRef = this.dialogForm.open(DownloadEntitiesComponent, {
            data: {
                model: network,
                downloadService: this.csvDownloadService,
                currentNetwork: this.currentNetwork,
                downloadAll: false,
                downloadNetwork: true,
                entityDownloadService: this.networkServiceV2.downloadNetwork.bind(this.networkServiceV2),
                entityType: 'Network',
                fileName: `${network.name}_network`,
                items: [network],
            },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    private goToDashboard(network) {
        this.apiService.setCurrentNetwork(network);
        const route = this.featureService.isCloudZiti ? '/infrastructure/dashboard' : '/v7/dashboard';
        this.router.navigate([route]);
    }

    private getUpgradableVersions(network: NetworkV2) {
        this.upgradeableVersions = this.networkUpgradeService.getUpgradeableVersions(network);
        return this.upgradeableVersions;
    }

    private upgrade = (network: NetworkV2) => {
        this.networkUpgradeService.requestUpgrade(network);
    };

    private restart(network: NetworkV2) {
        this.networkUpgradeService.requestRestart(network);
    }

    private resize(network: NetworkV2) {
        this.networkUpgradeService.requestResize(network);
    }

    public createBackup(network: NetworkV2) {
        const controllerId = network.networkController.id;
        this.networkServiceV2.createBackup(controllerId).subscribe(
            (result) => {
                this.growlerService.show(new GrowlerData('success', 'Success', 'Backup Creation Started Successfully'));
                return result;
            },
            (httpErrorResponse: { error: { errors: (string | undefined)[] } }) => {
                this.growlerService.show(
                    new GrowlerData('error', 'Error creating backup', httpErrorResponse.error.errors[0])
                );
            }
        );
    }

    private suspend(network: NetworkV2) {
        this.networkServiceV2.suspend(network.id).subscribe(
            (result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Suspend Started',
                        'Suspension was started successfully.<a href="' +
                            this.router.createUrlTree(['/process-executions']).toString() +
                            '">Click here to find out more</a>"'
                    )
                );
                return result;
            },
            (httpErrorResponse) => {
                this.growlerService.show(
                    new GrowlerData('error', 'Error suspending network', httpErrorResponse.error.errors[0])
                );
            }
        );
    }

    private resume(network: NetworkV2) {
        this.networkServiceV2.resume(network.id).subscribe(
            (result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Resume started',
                        'Resume was started successfully.<a href="' +
                            this.router.createUrlTree(['/process-executions']).toString() +
                            '">Click here to find out more</a>"'
                    )
                );
                return result;
            },
            (httpErrorResponse) => {
                this.growlerService.show(
                    new GrowlerData('error', 'Error resuming network', httpErrorResponse.error.errors[0])
                );
            }
        );
    }

    private openJson(item: NetworkV2) {
        this.dialogRef = this.dialogForm.open(JsonViewComponent, {
            data: {
                model: item,
            },
            height: '800px',
            width: '1200px',
        });
    }

    private openPublicCertModal(item: NetworkV2) {
        this.dialogRef = this.dialogForm.open(PublicCertConfirmModalComponent, {
            data: {
                model: item,
            },
            height: '800px',
            width: '1200px',
        });
    }

    private isfilterApplied() {
        this.filterHasChanged = true;
        this.filterApplied = _.some(this.columnFilters, (value) => !_.isEmpty(_.toString(value)));
    }

    private shouldShowNoData() {
        this.showNoData = this.items.length <= 0 && !this.filterApplied && !this.isLoading;
    }

    private initTableColumns() {
        const columnFilters = this.columnFilters;
        const headerComponentParams = {
            filterType: 'TEXTINPUT',
            columnFilters,
        };

        const provisionedDate = (row) => {
            const network = row.data as NetworkV2;
            return moment(network.createdAt).local().format('M/D/YY h:mm a');
        };

        const provider = (row) => {
            const network = row.data as NetworkV2;
            if (this.loadingProviders) {
                return 'Loading Provider...';
            } else {
                if (!network.networkController) {
                    return '';
                }
                return this.providers[network.networkController.id];
            }
        };

        this.columnDefs = [
            {
                colId: 'name',
                width: 200,
                minWidth: 100,
                field: 'name',
                tooltipField: 'name',
                sortColumn: this.sort.bind(this),
                headerName: 'Name',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                onCellClicked: (params) => {
                    this.goToDashboard(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
                sortable: true,
            },

            {
                colId: 'networkGroupName',
                width: 100,
                minWidth: 100,
                field: 'networkGroupName',
                tooltipField: 'networkGroupName',
                headerName: 'Network Group',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams: this.groupHeaderParams,
                onCellClicked: (params) => {
                    this.goToDashboard(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
                sortable: false,
                enableSorting: false,
            },
            {
                colId: 'status',
                width: 90,
                minWidth: 85,
                field: 'status',
                tooltipField: 'status',
                sortColumn: this.sort.bind(this),
                headerName: 'Status',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                onCellClicked: (params) => {
                    this.goToDashboard(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'region',
                width: 80,
                minWidth: 70,
                field: 'region',
                tooltipField: 'region',
                sortColumn: this.sort.bind(this),
                headerName: 'Region',
                headerComponentParams,
                headerComponent: TableHeaderDefaultComponent,
                onCellClicked: (params) => {
                    this.goToDashboard(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'provider',
                width: 100,
                minWidth: 85,
                valueFormatter: provider,
                field: 'provider',
                tooltipField: 'provider',
                headerName: 'Provider',
                headerComponent: TableHeaderDefaultComponent,
                onCellClicked: (params) => {
                    this.goToDashboard(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                sortable: false,
            },
            {
                colId: 'size',
                width: 60,
                minWidth: 50,
                field: 'size',
                tooltipField: 'size',
                sortColumn: this.sort.bind(this),
                headerName: 'Size',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                onCellClicked: (params) => {
                    this.goToDashboard(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'productVersion',
                minWidth: 110,
                width: 120,
                headerName: 'Version',
                field: 'productVersion',
                tooltipField: 'productVersion',
                sortable: false,
                enableSorting: false,
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                onCellClicked: (params) => {
                    if (params?.nfTextSelected) {
                        return;
                    }
                    this.openNetworkInfo(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                cellRenderer: 'versionUpgradeableComponent',
                cellRendererParams: {
                    service: this.upgrade,
                    isAuthorized: this.authorizationService.canUpgradeNetwork(this.currentNetwork?.id),
                },
            },
            {
                colId: 'provisionedAt',
                width: 100,
                minWidth: 85,
                valueFormatter: provisionedDate,
                field: 'createdAt',
                tooltipField: 'createdAt',
                headerName: 'Provision Date',
                headerComponent: TableHeaderDefaultComponent,
                onCellClicked: (params) => {
                    this.goToDashboard(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
                sortable: false,
                enableSorting: false,
            },
        ];
        if (this.featureService.powerUserEnabled) {
            this.columnDefs.push({
                colId: 'id',
                width: 200,
                minWidth: 100,
                field: 'id',
                tooltipField: 'id',
                sortColumn: this.sort.bind(this),
                headerName: 'ID',
                headerComponentParams,
                headerComponent: TableHeaderDefaultComponent,
                onCellClicked: (params) => {
                    this.copy(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            });
            this.columnDefs.push({
                colId: 'groupid',
                width: 100,
                minWidth: 100,
                field: 'networkGroupId',
                tooltipField: 'networkGroupId',
                headerName: 'NW Group Id',
                headerComponent: TableHeaderDefaultComponent,
                onCellClicked: (params) => {
                    this.copyNetworkGroupId(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
                sortable: false,
                enableSorting: false,
            });
        }
    }

    private async getAllNetworks() {
        this.isLoading = true;
        this.showList = false;
        const params = this.getPagedOptions();

        if (this.columnFilters.name && this.page !== 0 && !this.changedPage) {
            this.changedPage = true;
            this.isLoading = true;
            this.filterService.setPage(0, true);
            this.page = 1;
        }

        await this._loadV7networks(params);
        this.logger.info('V7 Networks', this.networksV7);

        // process list
        const processed = [];
        this.relevantNetworkGroups.clear();
        for (const network of this.networksV7) {
            this.relevantNetworkGroups.add(network.networkGroupId);
            processed.push(this._processModel(network));
        }
        this.groupHeaderParams.filterOptions = [];
        if (this.relevantNetworkGroups.size > 0) {
            this.groupHeaderParams.filterOptions = this.networkGroups
                .filter((g) => this.relevantNetworkGroups.has(g.id))
                .map((g) => ({ label: g.name, value: g.id }));
            this.groupHeaderParams.filterOptions.unshift({ label: 'All', value: undefined });
        }

        if (this.gridObj) {
            _.set(this.gridObj, 'api?.columnModel?.columnDefs[8].headerComponentParams', this.groupHeaderParams);
            _.forEach(this.gridObj?.columnApi?.columnModel?.columnDefs, (colDef: any) => {
                if (colDef?.colId === 'networkGroupId') {
                    colDef.headerComponentParams = this.groupHeaderParams;
                    const colState = this.gridObj.api.getColumnState();
                    this.gridObj.api.applyColumnState(colState);
                }
            });
        }

        this.deletedNetworkIds = [];
        this.items = processed;
        this.displayedItems = this.items;
        this.getHostInfo();

        this.showList = this.items.length > 0;
        this.isLoading = false;
        this.shouldShowNoData();
        this.isDeleteAction = this.anyToggled();
        this.totalElements = this.networkServiceV2.lastTotalCount;
        this.filterService.setTotalElements(this.totalElements);

        this.applyNetworkGroupFilter(this.networkGroupFilter);
    }

    private resetSelectedNetworkIds(filteredList) {
        this.displayedItems = filteredList;
        const newSelectedNetworkIds = [];
        for (const network of this.displayedItems) {
            if (
                (this.selectedNetworkIds.indexOf(network.id) > -1 || this.allToggled) &&
                network.status === 'PROVISIONED'
            ) {
                newSelectedNetworkIds.push(network.id);
                network.selected = true;
            } else {
                network.selected = false;
            }
        }
        this.selectedNetworkIds = newSelectedNetworkIds;
        this.isDeleteAction = this.anyToggled();
    }

    private refresh() {
        this.refresher.disableRefresh();
        if (this.authorizationService.canListNetworks()) {
            // this.networkService.get();
            this.getAllNetworks();
            this.getHostInfo();
        }
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval * 4);
    }

    private toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedNetworkIds = [];

        for (const item of this.displayedItems) {
            if (this.authorizationService.canDeleteNetwork(item.id, item.networkGroupId)) {
                item.selected = this.allToggled;
                if (this.allToggled) {
                    this.selectedNetworkIds.push(item.id);
                }
            }
        }

        this.isDeleteAction = this.anyToggled();
    }

    openMoveModal(item: NetworkV2) {
        this.dialogRef = this.dialogForm.open(MoveNetworkModalComponent, {
            data: {
                model: item,
            },
            height: '550px',
            width: '590px',
        });
    }

    openRestoreModal(item: NetworkV2) {
        this.dialogRef = this.dialogForm.open(NetworkRestoreModalComponent, {
            data: {
                model: item,
            },
            height: '550px',
            width: '590px',
        });
    }

    private copy(item: Network) {
        navigator.clipboard.writeText(item.id);
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Network Id Copied',
                'The networkId has been copied to your clipboard'
            )
        );
    }

    private copyNetworkGroupId(item: NetworkV2) {
        navigator.clipboard.writeText(item.networkGroupId);
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Network Group Id Copied',
                'The networkGroupId has been copied to your clipboard'
            )
        );
    }

    private openNetworkInfo(network: Network) {
        this.dialogRef = this.dialogForm.open(NetworkInfoV2Component, {
            data: { network: network },
            height: '500px',
            width: '600px',
            autoFocus: false,
        });
    }

    private openSupportInfo(network: Network) {
        this.dialogRef = this.dialogForm.open(NetworkSupportComponent, {
            data: { network: network },
            width: '800px',
            autoFocus: false,
        });
    }

    private openManageConfigPort(item: NetworkV2) {
        this.dialogRef = this.dialogForm.open(ManageControllerConfigModalComponent, {
            data: { model: item },
            height: '800px',
            width: '1200px',
            autoFocus: false,
        });
    }

    private openNotification() {
        this.router.navigate(['/notifications']);
    }

    private openRename(network: Network) {
        this.dialogRef = this.dialogForm.open(RenameFormComponent, {
            data: { name: network.name },
            height: '250px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.networkServiceV2
                    .renameNetwork(network.id, result)
                    .toPromise()
                    .then(() => {
                        this.growlerService.show(
                            new GrowlerData('success', 'Success', 'Network renamed', 'The network has been renamed')
                        );

                        this.refresh();
                    })
                    .catch((error) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Network rename failed',
                                JSON.stringify(error.error.errors[0])
                            )
                        );
                    });
            }
        });
    }

    private toggle(item: NetworkV2) {
        const model = this.apiService.getNetworkModel(item);
        const orgId = model.networkGroupId;

        if (this.authorizationService.canDeleteNetwork(model.id, orgId)) {
            // use 'item' instead of 'model' so that it propagates to the view
            item.selected = !item.selected;

            if (this.allToggled) {
                this.allToggled = !this.allToggled;
            }

            const index = this.selectedNetworkIds.indexOf(model.id);
            if (index > -1) {
                this.selectedNetworkIds.splice(index, 1);
            } else {
                this.selectedNetworkIds.push(model.id);
            }

            this.isDeleteAction = this.anyToggled();
        } else {
            this.logger.info('Not authorized to delete network');
        }
    }

    public getHostInfo() {
        const networkControllerIds = [];
        for (const item of this.displayedItems) {
            if (!item.networkController) {
                continue;
            }
            networkControllerIds.push(item.networkController.id);
            this.logger.info('networkControllerIds', networkControllerIds);
        }
        if (networkControllerIds.length > 0) {
            this.networkServiceV2.getEmbeddedHostInfo(networkControllerIds).subscribe((data) => {
                for (const item of data) {
                    if (item.host) {
                        this.providers[item.id] = item.host[0].provider;
                    }
                }
                if (this.gridApi && this.gridObj) {
                    this.loadingProviders = false;
                    this.gridApi.refreshCells({ force: true });
                }
            });
        }
    }

    private anyToggled() {
        if (this.selectedNetworkIds.length > 0) {
            if (
                this.selectedNetworkIds.length ===
                this.items.length - this.inDeletingStatusCount - this.noDeleteAuthCount
            ) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    private toggleMenu(event, index) {
        if (event) event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }

    private openConfirmDeleteDlg(item: NetworkV2) {
        const deleteString = 'Are you sure you would like to delete the following Network:';
        const afterListString = 'Doing so will delete all resources on this network';

        const data = {
            title: 'Delete',
            appendId: 'Networks',
            subtitle: deleteString,
            afterListText: afterListString,
            bulletList: [item],
            secondBulletList: [],
            icon: 'DestructiveDelete',
            action: 'Yes',
            isDestructive: true,
            itemName: item.name,
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '440px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            // if the result has a property loggingOut, rather than being just a boolean value, the user is being
            //  logged out of the console, and we should close the dialog without continuing
            if (!result) {
                this.deleteConfirmed(false);
            } else if (result['loggingOut'] === undefined) {
                this.deleteConfirmed(true);
            }
        });
    }

    private confirmDelete(requestItem: NetworkV2) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }
        let item;
        for (const ntwk of this.items) {
            if (ntwk.id === requestItem.id) {
                item = ntwk;
                break;
            }
        }

        if (item) {
            item.selected = true;

            const index = this.selectedNetworkIds.indexOf(item.id);
            if (index === -1) {
                this.selectedNetworkIds.push(item.id);
            }
            this.isDeleteAction = true;
            this.openConfirmDeleteDlg(item);
        }
    }

    private deleteConfirmed(deleteConfirmed: boolean) {
        if (deleteConfirmed) {
            this.refresher.disableRefresh();
            this.isLoading = true;
            let recordFound = false;
            for (let i = 0; i < this.displayedItems.length; i++) {
                if (this.displayedItems[i].selected) {
                    this.logger.info('deleting network', this.displayedItems[i]);
                    this.deleting++;
                    recordFound = true;
                    this.logger.log('Going to delete network', this.displayedItems[i]);

                    this.networkServiceV2.deleteResource(this.displayedItems[i]).subscribe(
                        (data) => {
                            this.logger.log('Delete request sent', data);

                            // forcing the deleted items to become untoggled
                            this.toggle(this.displayedItems[i]);

                            this.deletedNetworkIds.push(this.displayedItems[i].id);
                            this.deleting--;

                            // checking if the current item that is being deleted is set to the current network
                            if (this.currentNetwork.id === this.displayedItems[i].id) {
                                // if it is, flagging setNetworkAfterDelete as true so that the current network may be updated during the refresh
                                this.setNetworkAfterDelete = true;
                            }
                            if (this.deleting <= 0) {
                                this.isLoading = true;
                                this.refresh();
                                this._checkCurrentNetworkAfterDelete();
                            }
                        },
                        (error) => {
                            // forcing the deleted items to become untoggled
                            this.toggle(this.displayedItems[i]);
                            this.logger.error('Error deleting network', this.displayedItems[i]);
                            this.growlerService.show(new GrowlerData('error', 'Network deletion failed: ' + error));
                        }
                    );
                }
            }
            if (!recordFound) this.logger.error('no selected record found to delete');
        } else {
            this.logger.info('delete action canceled');
            this.isDeleteAction = false;
            for (const network of this.items) {
                network.selected = false;
            }
            this.allToggled = false;
            this.selectedNetworkIds = [];
        }
    }

    private getPagedOptions(): PagedGetOption {
        const params: PagedGetOption = {
            headers: {
                accept: 'application/hal+json',
            },
            params: {},
            pageParams: {
                page: this.page - 1,
                size: this.pageSize,
            },
            sort: this.paramList.sort,
        };
        if (this.featureService.isCloudZiti) {
            params.params.embed = 'network-controllers,auth-policies';
        }
        if (this.filterString || this.columnFilters.name) {
            let filterVal = this.filterString;
            if (!_.isEmpty(this.columnFilters.name)) {
                filterVal = this.columnFilters.name;
            }
            const escapedChars = filterVal.replace('%', '\\%').replace('_', '\\_');
            params.params['name'] = `%${escapedChars}%`;
        }

        if (this.columnFilters.status) {
            const statusEscaped = this.columnFilters.status.replace('%', '\\%'); // .replace('_', '\\_');
            params.params['status'] = `${statusEscaped}`;
        }
        if (this.columnFilters.region) {
            const regionEscaped = this.columnFilters.region.replace('%', '\\%'); // .replace('_', '\\_');
            params.params['region'] = `${regionEscaped}`;
        }
        if (this.columnFilters.id) {
            const idEscaped = this.columnFilters.id.replace('%', '\\%'); // .replace('_', '\\_');
            params.params['id'] = `${idEscaped}`;
        }
        if (this.columnFilters.productVersion) {
            const productVersionEscaped = this.columnFilters.productVersion.replace('%', '\\%'); // .replace('_', '\\_');
            params.params['productVersion'] = `${productVersionEscaped}%`;
        }
        if (this.columnFilters.networkGroupName) {
            params.params['networkGroupId'] = this.columnFilters.networkGroupName;
        }
        return params;
    }

    private getNetworkGroupName(network): string {
        const networkGroupId = network.networkGroupId;

        const name = this.networkGroupMap[networkGroupId] === null ? '--' : this.networkGroupMap[networkGroupId];
        return name;
    }

    private setNetworkGroupNameFilter(networkGroupId) {
        if (!this.networkGroupMap[networkGroupId]) {
            this.selectedNetworkGroupName = 'Network Group';
            this.networkGroupFilter = false;
        } else {
            this.selectedNetworkGroupName = this.networkGroupMap[networkGroupId];
            this.networkGroupFilter = networkGroupId || false;
        }
        this.applyNetworkGroupFilter(this.networkGroupFilter);
    }

    private applyNetworkGroupFilter(networkGroupId) {
        if (networkGroupId) {
            const networkGroupFilteredItems = [];
            for (const item of this.items) {
                if (item.networkGroupId === networkGroupId) {
                    networkGroupFilteredItems.push(item);
                }
            }

            this.resetSelectedNetworkIds(networkGroupFilteredItems);
        } else {
            this.resetSelectedNetworkIds(this.items);
        }
    }

    private _getStatusLabel(network, version) {
        if (version >= 7) {
            return network.status;
        }
    }

    private async _loadV7networks(params) {
        return await this.networkServiceV2.getNetworksPage(params).then(
            (networks: NetworkV2[]) => {
                const v2Networks = [];
                for (let v2Network of networks) {
                    // only show v2 networks that are in a living state
                    v2Network = this.apiService.getNetworkModel(v2Network);
                    if (v2Network.status !== 'DELETED' && v2Network.productVersion !== null) {
                        v2Networks.push(v2Network as NetworkV2);
                        v2Network['isUpgradable'] = this.networkUpgradeService.isUpgradeable(v2Network);
                    }
                }
                const newTotalPages = this.networkServiceV2.lastPageCount + 1;
                this.filterService.setTotalPages(newTotalPages);
                // if the number of pages has decreased and the user is past the last page now
                if (this.page > newTotalPages) {
                    // move back one page
                    this.page = newTotalPages;
                }
                this.filterService.setPage(this.page);
                this.totalElements = this.networkServiceV2.lastTotalCount;
                this.filterService.setTotalElements(this.totalElements);
                this.filterService.setTotalPages(this.networkServiceV2.lastPageCount);
                this.networksV7 = v2Networks;
            },
            (error) => {
                this.logger.error('Error returning V2 networks for networks-combined component', error);
                this.networksV7 = [];
            }
        );
    }

    // add some additional fields to the network model
    private _processModel(network) {
        const model = this.apiService.getNetworkModel(network);
        const version = this.apiService.getNetworkVersion(network);
        const minVersion = this.apiService.getNetworkMinorVersion(network);
        const patchVersion = this.apiService.getNetworkPatchVersion(network);

        model.selected = false;
        model['actionList'] = this._getActions(model);
        model['statusLabel'] = this._getStatusLabel(model, version);
        model['majorVersion'] = version;
        model['version'] = `${version}.${minVersion}.${patchVersion}`;
        model['networkGroupName'] = this.getNetworkGroupName(network);
        model['upgradeableVersions'] = this.getUpgradableVersions(network);
        model['parentVersion'] = this.currentNetwork?.productVersion;
        if (
            this.currentNetwork &&
            this.currentNetwork['upgradeableVersions'] &&
            this.currentNetwork['upgradeableVersions'].length > 0
        ) {
            if (this.authorizationService.isSupportUser()) {
                model['parentVersion'] = this.currentNetwork['upgradeableVersions'][0].networkVersion;
            } else {
                for (const rec of this.currentNetwork['upgradeableVersions']) {
                    if (rec.recommended === true) {
                        model['parentVersion'] = this.currentNetwork['upgradeableVersions'][0].networkVersion;
                        break;
                    }
                }
            }
        }
        return model;
    }

    // determine what actions we can perform on this network model
    private _getActions(network) {
        const actions = [];
        actions.push('get');
        actions.push('firewall');
        if (!this.featureService.isCloudZiti) {
          actions.push('download');
          actions.push('downloadAll');
        }
        actions.push('notifications');

        if (this.featureService.powerUserEnabled) {
            actions.push('json');
        }

        if (network.publicCertEnabled === false && this.featureService.powerUserEnabled) {
            actions.push('publicCert');
        }

        if (this.authorizationService.isSupportUser() && network.region !== 'none') {
            actions.push('suspend');
            actions.push('resume');
            actions.push('restart');
            actions.push('resize');
            actions.push('supportinfo');
            actions.push('move');
            actions.push('createBackup');
            actions.push('restoreBackup');
            actions.push('configPort');
        }
        if (this.authorizationService.isSupportUser() && network.region === 'none') {
            // actions.push('suspend');
            // actions.push('resume');
            actions.push('restart');
            // actions.push('resize');
            actions.push('supportinfo');
            // actions.push('move');
            actions.push('createBackup');
            actions.push('restoreBackup');
            actions.push('configPort');
        }
        if (this.authorizationService.isSupportUser()) {
            actions.push('upgrade');
        }

        const networkGroupId = network.networkGroupId;

        if (this.authorizationService.canCreateNetworks(this.currentTenant.id)) {
            actions.push('rename');
        }
        if (this.authorizationService.canDeleteNetwork(network.id, networkGroupId)) {
            // && version < 7
            actions.push('delete');
            this.hasSomeDeletePermission = true;
        } else {
            this.noDeleteAuthCount++;
        }

        return actions;
    }

    private _checkCurrentNetworkAfterDelete() {
        // checking if the current network was just deleted
        if (this.setNetworkAfterDelete) {
            // if it was, set the setNetworkAfterDelete flag to false
            this.setNetworkAfterDelete = false;

            // if there is at least one network, set the networkWasUpdated flag to false
            let networkWasUpdated = false;

            // iterating through the list of networks until we find the first network that is not deleting
            for (const network of this.items) {
                if (
                    network.status !== '800' &&
                    network.status !== 'DELETED' &&
                    network.status !== 'DELETING' &&
                    this.deletedNetworkIds.indexOf(network.id) === -1
                ) {
                    // if the current network is not deleting, set it as the current network
                    this.apiService.setCurrentNetwork(network);

                    // marking the networkWasUpdated flag as true
                    networkWasUpdated = true;
                    break;
                }
            }

            // if no networks were found that were not in the process of deleting, set the network to a new network
            if (!networkWasUpdated) {
                this.apiService.setCurrentNetwork(new Network({}));
            }
        }
    }
}
