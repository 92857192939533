<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="ZitiServiceRouterPolicyAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="isEditing">Edit Your Service Edge Router Policy</span>
            <span *ngIf="!isEditing">Create A New Service Edge Router Policy</span>
        </div>
        <div class="subtitle">Enter your policy details</div>
        <div
            class="subhelp"
            matTooltip="Service Edge Router Policies specify the Edge Routers which Services are allowed to utilize. An Service MUST have access at least one Edge Router in order to be used in a NetFoundry Network."
            matTooltipPosition="below"
        >
            What's a Service Edge Router Policy?
        </div>
        <div class="sized full">
            <div class="grid half appContainer">
                <div class="selectContainer">
                    <div class="label row">
                        Policy Name
                        <span [hidden]="errors['name']" class="note">Required</span>
                    </div>
                    <div class="row">
                        <input
                            (change)="validate()"
                            (keyup.enter)="save()"
                            [(ngModel)]="model.name"
                            [disabled]="isEditing && !canEdit"
                            [ngClass]="{ error: errors['name'] }"
                            id="EditName"
                            placeholder="Name this policy"
                            type="text"
                        />
                        <span [hidden]="!errors['name']" class="err">
                            {{ errors['name'] }}
                        </span>
                    </div>
                    <div class="label row">
                        Service Attributes
                        <div
                            class="infoicon"
                            matTooltip="Associates the provided service and service groups to this policy."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row">
                        <app-tags-selector
                            (addAttribute)="addServiceAttribute($event)"
                            (removeAttribute)="removeServiceAttribute($event)"
                            [availableAttributes]="serviceAttributes"
                            [disableField]="isEditing && !canEdit"
                            [errorMessage]="errors['serviceAttributes']"
                            [hasError]="serviceAttributeError"
                            [isLoading]="isLoadingServiceAttr"
                            [placeholder]="'Type to select service attributes'"
                            [selectedAttributes]="selectedServiceAttributes"
                            id="ServiceAttributes"
                        ></app-tags-selector>
                    </div>
                    <div class="label row">
                        Edge Router Attributes
                        <div
                            class="infoicon"
                            matTooltip="Associates the provided edge routers and edge router groups to this policy."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row">
                        <app-tags-selector
                            (addAttribute)="addEdgeRouterAttribute($event)"
                            (removeAttribute)="removeEdgeRouterAttribute($event)"
                            [availableAttributes]="edgeRouterAttributes"
                            [disableField]="isEditing && !canEdit"
                            [errorMessage]="errors['edgeRouterAttributes']"
                            [hasError]="erAttributeError"
                            [isLoading]="isLoadingEdgeRouterAttr"
                            [placeholder]="'Choose edge routers to associate to this policy'"
                            [selectedAttributes]="selectedEdgeRouterAttributes"
                            id="EdgeRouterAttributes"
                        ></app-tags-selector>
                    </div>
                </div>
                <div class="previewContainer">
                    <div class="previewListContainer grid half thin">
                        <app-preview-list
                            [allNames]="v2Services"
                            [isLoading]="isServicePreview"
                            label="Services Preview"
                        ></app-preview-list>
                        <app-preview-list
                            [allNames]="v2EdgeRouters"
                            [isLoading]="isEdgeRouterPreview"
                            label="Edge Routers Preview"
                        ></app-preview-list>
                    </div>
                </div>
            </div>

            <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 1.25rem">
                <button (click)="save()" *ngIf="!processing && !isLoading" class="save" id="SavePolicyZitiButton">
                    {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="isLoading || processing" class="save confirm" disabled>
                    {{ isLoading ? 'Loading' : 'Saving' }}
                    <span class="spinner"></span>
                </button>
            </div>
        </div>
    </div>

    <div (click)="hide()" class="buttonBall close" id="EscZitiPolicyClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
