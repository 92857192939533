<div class="row" style="margin: 0; padding: 0 0rem 0 0.625rem">
    <div *ngIf="!isLoading && !noData" class="grid">
        <!--  -->

        <div class="UsageList">
            <div class="tHeadRow list" style="border-bottom: none">
                <div class="tHead t30 nameCol">Name</div>
                <div class="tHead t20">Dial Health<span class="usage-type" data-context="UsageType"></span></div>
                <div class="tHead t20">Success<span class="usage-type" data-context="UsageType"></span></div>
                <div class="tHead t20">Failure<span class="usage-type" data-context="UsageType"></span></div>
            </div>

            <div class="usageByGateawy row list" id="UsageListData" style="padding: 0rem">
                <cdk-virtual-scroll-viewport [itemSize]="25" class="scroll-viewport">
                    <div
                        (click)="openMetrics(item)"
                        *cdkVirtualFor="let item of items; let i = index"
                        [ngClass]="{ selected: currentItemFilter === item.name }"
                        class="dataRow"
                    >
                        <div class="tRow filterItem" id="item_{{ i }}">
                            <div class="tCol tLeft t30">
                                <span>{{ item.name }}</span>
                            </div>
                            <div class="tCol t20 secondcol">{{ item.dialHealth | percent }}</div>
                            <div class="tCol t20 secondcol">{{ item.success }}</div>
                            <div class="tCol t20 secondcol">{{ item.failure }}</div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isLoading && noData" class="noDataZiti">No Data</div>
<div *ngIf="isLoading" style="margin-top: 9.375rem">
    <app-loader [isLoading]="isLoading"></app-loader>
</div>
