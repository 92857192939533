<div class="row" style="width: 95%">
    <div *ngIf="!isLoading && !noData" class="grid half">
        <app-highcharts-generic
            [chartheight]="height"
            [options]="top_five_options"
            [series]="top_five_series"
            style="padding-left: 4.375rem; padding-top: 0.625rem"
        ></app-highcharts-generic>

        <div class="UsageList">
            <div class="tHeadRow list" style="border-bottom: none">
                <div class="tHead t70">Name</div>
                <div class="tHead t30 tRight">Total Usage<span data-context="UsageType"></span></div>
            </div>

            <div class="usageByGateawy row list" id="UsageListData" style="background: transparent">
                <div *ngFor="let item of items; let i = index">
                    <div class="tRow" id="vtcRow_{{ item.key }}" style="background: transparent; border-left: none">
                        <div class="tCol tLeft t70">
                            <span class="bubble highcharts-color-{{ i }}"></span>
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="tCol t30 tLeft">{{ item.count | tobytes: true }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isLoading && noData" class="noDataZiti">No Data</div>
<app-loader [isLoading]="isLoading"></app-loader>
