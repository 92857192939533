<div class="tCol desktop" id="RowSelect4_{{ item.id }}">
    <span>{{ item.productVersion }}</span>
    <!-- <button
        (click)="upgrade($event)"
        *ngIf="cellParams.isAuthorized && item['isUpgradable']"
        class="save alert confirm upgradebadge"
    >
        Upgrade
    </button> -->
    <span
        *ngIf="cellParams.isAuthorized && item['isUpgradable']"
        style="padding-left: 0.3125rem"
        class="pi pi-arrow-circle-up"
    ></span>
</div>
