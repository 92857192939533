<div id="NetworkTimelineContainer">
    <div *ngIf="!noData && canReadElasticSearch" class="row" id="NetworkArea">
        <div [chart]="chart" id="NetworkChart" style="min-width: 19.375rem; height: 20rem; margin: 0 auto">
            <!-- chart renders here -->
        </div>
    </div>

    <div *ngIf="noData && !networkLoading" class="noData" id="NoNetworkArea">No Data</div>
    <div *ngIf="!canReadElasticSearch && !networkLoading" class="noData" id="NoNetworkArea">No Permissions</div>
    <app-loader [isLoading]="networkLoading"></app-loader>
</div>
