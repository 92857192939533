<div [ngClass]="{ inline: isInline, readOnly: !canEdit && !isNew }" class="fullModal opened" id="ZitiServiceAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="!isEditing">Create A New Service</span>
            <span *ngIf="isEditing">Edit Your Service</span>
        </div>
        <div *ngIf="!isEditing" class="subtitle">Enter your service details</div>
        <div *ngIf="isEditing" class="subtitle">Edit your service details</div>
        <div
            class="subhelp"
            matTooltip="A service defines how connections are created for applications. Services are a fundamental building block of a NetFoundry overlay network."
            matTooltipPosition="below"
        >
            What's a Service?
            <div class="infoicon"></div>
        </div>
        <div class="sized full">
            <div class="grid two">
                <div class="firstColumn">
                    <div class="label row">
                        Service Name
                        <span class="note" [hidden]="errors['name']">Required</span>
                    </div>
                    <div class="row">
                        <input
                            (keyup.enter)="save(true)"
                            [(ngModel)]="model.name"
                            [disabled]="isEditing && !canEdit"
                            [ngClass]="{ error: errors['name'] }"
                            id="EditName"
                            maxlength="64"
                            minlength="5"
                            placeholder="Name this service"
                            type="text"
                            (change)="validate()"
                        />
                        <span [hidden]="!errors['name']" class="err">
                            {{ errors['name'] }}
                        </span>
                    </div>

                    <div class="label row">
                        Service Attributes
                        <div
                            class="infoicon"
                            matTooltip="Service Attributes are tags applied to a service. Apply the same tag to other services to form a group of services."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row platform-serivce-attributes">
                        <app-tags-selector
                            (addAttribute)="addServiceAttribute($event)"
                            (removeAttribute)="removeServiceAttribute($event)"
                            [availableAttributes]="serviceAttributes"
                            [disableField]="isEditing && !canEdit"
                            [hasError]="attributeError"
                            [errorMessage]="errors['attributes']"
                            [isLoading]="isLoadingSvcAttr"
                            [placeholder]="'Add attributes to group services'"
                            [selectedAttributes]="selectedServiceAttributes"
                            [errorMessage]="errors['attributes']"
                            id="Service"
                        ></app-tags-selector>
                    </div>

                    <div *ngIf="model.modelType !== 'TunnelerToEdgeRouter'">
                        <div [ngClass]="{ disabled: isEditing && !canEdit }" class="label row">
                            Edge Router Attributes
                            <div
                                class="infoicon"
                                matTooltip="The edge routers participating in this service. For all edge routers leave this field blank."
                                matTooltipPosition="below"
                            ></div>
                            <span class="note">Optional</span>
                        </div>
                        <div class="row">
                            <app-tags-selector
                                (addAttribute)="addEdgeRouterAttribute($event)"
                                (removeAttribute)="removeEdgeRouterAttribute($event)"
                                [availableAttributes]="edgeRouterAttributes"
                                [disableField]="isEditing && !canEdit"
                                [hasError]="erAttributeError"
                                [isLoading]="isLoadingEdgeRouterAttr"
                                [placeholder]="'Default: #all, restricts routers available to this service'"
                                [selectedAttributes]="selectedEdgeRouterAttributes"
                                id="Router"
                                [errorMessage]="errors['edgeRouterAttributes']"
                            ></app-tags-selector>
                        </div>
                    </div>
                    <div class="label row">
                        End-to-End Encryption
                        <div
                            class="infoicon"
                            matTooltip="Encrypts packets when entering or leaving the network. Cannot be enabled for router hosted UDP services"
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">OPTIONAL</span>
                    </div>
                    <div class="row">
                        <div class="encryptionpod">
                            <span class="encryptiontext">ENCRYPT THIS SERVICE</span>
                            <div
                                (click)="toggleEncryption()"
                                [ngClass]="{
                                    on: model.encryptionRequired,
                                    disabled: disableEncryptionToggle
                                }"
                                [title]="
                                    model.modelType === 'TunnelerToEndpoint'
                                        ? 'Encryption must be enabled for' +
                                          (featureService?.disableNewNavigation ? 'Endpoint' : 'Identity') +
                                          'Hosted services'
                                        : isEditing
                                        ? 'Encryption can\'t be changed once service has been created'
                                        : ''
                                "
                                class="toggle"
                                style="float: right; margin-top: 0.9375rem; margin-right: 0.75rem"
                            >
                                <span [hidden]="!model.encryptionRequired" style="margin-left: -0.9375rem">YES</span>
                                <span [hidden]="model.encryptionRequired" style="margin-right: -0.9375rem">NO</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isEditing" class="associated row">
                        <app-preview-list
                            (itemSelected)="endpointSelected($event)"
                            [allNames]="assocEndpointNames"
                            [clickable]="!isInline"
                            [isLoading]="isLoadingAssocEndpoints"
                            label="Associated {{ featureService?.disableNewNavigation ? 'Endpoints' : 'Identities' }}"
                        ></app-preview-list>
                        <app-preview-list
                            (itemSelected)="routerSelected($event)"
                            *ngIf="featureService.powerUserEnabled"
                            [allNames]="assocRouterNames"
                            [clickable]="!isInline"
                            [isLoading]="isLoadingAssocRouters"
                            label="Associated Edge Routers"
                        ></app-preview-list>
                    </div>
                </div>
                <div>
                    <div class="label row">
                        Client Configuration
                        <div
                            class="infoicon"
                            matTooltip="IP/Hostname used by the client to reach the application. Note: Does not need to match Host Configuration."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">REQUIRED</span>
                    </div>
                    <div class="darkBox">
                        <div></div>
                        <div [ngClass]="{ closed: !isClientOpen }" class="grid two">
                            <div [ngClass]="{ error: errors['clientHostName'] }" class="label">
                                INTERCEPT HOST NAME / IP
                                <div
                                    class="infoicon"
                                    matTooltip="Protocol IP/Hostname must include a '.'"
                                    matTooltipPosition="below"
                                ></div>
                            </div>
                            <div [ngClass]="{ error: errors['clientPort'] }" class="label">PORT</div>
                            <div>
                                <input
                                    [(ngModel)]="model.model.clientIngress.host"
                                    [disabled]="isEditing && !canEdit"
                                    [ngClass]="{ error: errors['clientHostName'] }"
                                    id="ConfigServiceClientHostName"
                                    placeholder="host.name"
                                    (change)="validate()"
                                />
                                <span [hidden]="!errors['clientHostError']" class="err">
                                    {{ errors['clientHostError'] }}
                                </span>
                            </div>
                            <div>
                                <input
                                    [(ngModel)]="model.model.clientIngress.port"
                                    [disabled]="isEditing && !canEdit"
                                    [ngClass]="{ error: errors['clientPort'] }"
                                    id="ConfigServiceClientPort"
                                    placeholder="0"
                                    (change)="validate()"
                                    type="number"
                                />
                                <span [hidden]="!errors['clientPortError']" class="err">
                                    {{ errors['clientPortError'] }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="label row">
                        Host Configuration
                        <div
                            class="infoicon"
                            matTooltip="Protocol IP/Hostname and port of destination application. Note: Must be reachable from assigned {{
                                featureService?.disableNewNavigation ? 'endpoint' : 'identity'
                            }}/host."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">REQUIRED</span>
                    </div>
                    <div class="darkBox">
                        <div [ngClass]="{ closed: !isRouterOpen, disabled: isEditing }" class="grid splitter">
                            <div class="label">
                                Native Application
                                <span style="color: var(--icon)">SDK BASED</span>
                                <div
                                    class="infoicon"
                                    matTooltip="An application built with a Ziti SDK."
                                    matTooltipPosition="below"
                                ></div>
                            </div>
                            <div class="switchGrid">
                                <div>
                                    <div
                                        (click)="selectedEndpoints.length === 0 && !isEditing && sdkTunneler()"
                                        [ngClass]="{ on: model.modelType === 'TunnelerToSdk' }"
                                        class="toggle"
                                        style="margin-top: 0.1875rem"
                                    >
                                        <span [hidden]="model.modelType === 'TunnelerToSdk'" style="margin-right: -0.9375rem"
                                            >NO</span
                                        >
                                        <span [hidden]="model.modelType !== 'TunnelerToSdk'" style="margin-left: -0.9375rem"
                                            >YES</span
                                        >
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <mat-radio-group
                            (change)="typeSelection($event)"
                            *ngIf="model.modelType !== 'TunnelerToSdk'"
                            [(ngModel)]="model.modelType"
                            [disabled]="isEditing"
                            [ngClass]="{ disabled: isEditing }"
                            aria-label="Select an option"
                            class="nf-hosted-radio"
                            style="line-height: 2.8125rem"
                        >
                            <mat-radio-button value="TunnelerToEndpoint"
                                >{{
                                    featureService?.disableNewNavigation ? 'ENDPOINT' : 'IDENTITY'
                                }}
                                HOSTED</mat-radio-button
                            >
                            <span style="padding: 1.25rem; color: var(--icon); font-weight: 600">OR</span>
                            <mat-radio-button value="TunnelerToEdgeRouter">ROUTER HOSTED</mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="model.modelType === 'TunnelerToEdgeRouter'">
                            <div
                                *ngFor="let edgeRouterHost of model.model.edgeRouterHosts; let i = index"
                                [attr.data-index]="i"
                                class="row mini-pod"
                            >
                                <div>
                                    <div class="label">
                                        Select a Router to Host this Service
                                        <div
                                            class="infoicon"
                                            matTooltip="The router where traffic will leave the overlay network."
                                            matTooltipPosition="below"
                                        ></div>
                                    </div>
                                    <select
                                        [(ngModel)]="edgeRouterHost.edgeRouterId"
                                        [disabled]="isEditing && !canEdit"
                                        [ngClass]="{
                                            error: errors['egressRouterId'] && errors['egressRouterId'][i]
                                        }"
                                        id="ConfigRouters"
                                    >
                                        <option value="">Select an Edge Router</option>
                                        <option
                                            *ngFor="let edgeRouter of edgeRoutersList | sortby: 'name'"
                                            [value]="edgeRouter.id ? edgeRouter.id : edgeRouter.edgeRouterName"
                                        >
                                            {{
                                                edgeRouter.name
                                                    ? edgeRouter.name.replace('@', '')
                                                    : edgeRouter.edgeRouterName
                                            }}
                                        </option>
                                    </select>
                                    <span [hidden]="erIdError !== i" class="err">
                                        {{ errors['erIdError'] }}
                                    </span>
                                    <div [ngClass]="{ closed: model.modelType === 'TunnelerToSdk' }" class="grid tre">
                                        <div [ngClass]="{ error: errors['protocol'] }" class="label">
                                            Protocol
                                            <div
                                                class="infoicon"
                                                matTooltip="Router Hosted UDP services cannot enable end to end encryption"
                                                matTooltipPosition="below"
                                            ></div>
                                        </div>
                                        <div [ngClass]="{ error: errors['serverHostName'] }" class="label">
                                            Host Name / IP
                                            <div
                                                class="infoicon"
                                                matTooltip="The provided hostname/ip must be addressable from the selected host."
                                                matTooltipPosition="below"
                                            ></div>
                                        </div>
                                        <div [ngClass]="{ error: errors['serverPort'] }" class="label">Port</div>
                                        <div>
                                            <select
                                                (change)="protocolSelection($event) && validate()"
                                                [(ngModel)]="edgeRouterHost.serverEgress.protocol"
                                                [disabled]="isEditing && !canEdit"
                                                [ngClass]="{ error: errors['protocol'] }"
                                                id="ConfigRouterProtocol"
                                            >
                                                <option [ngValue]="undefined">Protocol Type</option>
                                                <option value="tcp">TCP</option>
                                                <option value="udp">UDP</option>
                                            </select>
                                            <span [hidden]="erPortError !== i" class="err">
                                                {{ errors['erPortError'] }}
                                            </span>
                                        </div>
                                        <div>
                                            <input
                                                [(ngModel)]="edgeRouterHost.serverEgress.host"
                                                [disabled]="isEditing && !canEdit"
                                                [ngClass]="{ error: errors['serverHostName'] }"
                                                id="ConfigRouterHostName"
                                                placeholder="host.name"
                                                (change)="validate()"
                                            />
                                            <span [hidden]="erHostError !== i" class="err">
                                                {{ errors['erHostError'] }}
                                            </span>
                                        </div>
                                        <div>
                                            <input
                                                [(ngModel)]="edgeRouterHost.serverEgress.port"
                                                [disabled]="isEditing && !canEdit"
                                                [ngClass]="{ error: errors['serverPort'] }"
                                                id="ConfigRouterPort"
                                                placeholder="0"
                                                (change)="validate()"
                                                type="number"
                                            />
                                            <span [hidden]="erPortError !== i" class="err">
                                                {{ errors['erPortError'] }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    (click)="deleteHAEdgeRouter(i)"
                                    *ngIf="model.model.edgeRouterHosts.length > 1"
                                    class="remove"
                                >
                                    <div class="icon-HamburgerAppClose"></div>
                                </button>
                            </div>
                            <button (click)="addHAEdgeRouter()" class="save another">ADD ANOTHER EDGE ROUTER</button>
                        </div>
                        <div
                            *ngIf="model.modelType === 'TunnelerToEndpoint' || model.modelType === 'TunnelerToSdk'"
                            class="row"
                        >
                            <div class="label">
                                Select {{ featureService?.disableNewNavigation ? 'Endpoints' : 'Identities' }} to Host
                                this Service
                                <div
                                    class="infoicon"
                                    matTooltip="The associated {{
                                        featureService?.disableNewNavigation ? 'endpoints' : 'identities'
                                    }} capable of accepting connections from clients."
                                    matTooltipPosition="below"
                                ></div>
                            </div>

                            <app-tags-selector
                                (addAttribute)="addEndpointsAttributes($event)"
                                (removeAttribute)="removeEndpointAttribute($event)"
                                [errorMessage]="errors['bindEndpointAttributes']"
                                [availableAttributes]="endpointAttributes"
                                [disableField]="isEditing && !canEdit"
                                [hasError]="endpointAttributeError"
                                [isLoading]="isLoadingEndpointAttr"
                                [placeholder]="
                                    'Choose the ' +
                                    (featureService?.disableNewNavigation ? 'endpoint' : 'identity') +
                                    'to associate to this Service'
                                "
                                [selectedAttributes]="selectedEndpointAttributes"
                                id="Service"
                            ></app-tags-selector>
                            <div
                                *ngIf="model.modelType !== 'TunnelerToSdk'"
                                [ngClass]="{ disabled: model.modelType === 'TunnelerToSdk' }"
                                class="darkBoxSub"
                            >
                                <div [ngClass]="{ closed: model.modelType === 'TunnelerToSdk' }" class="grid tre">
                                    <div [ngClass]="{ error: errors['protocol'] }" class="label">
                                        Protocol
                                        <div
                                            class="infoicon"
                                            matTooltip="Router Hosted UDP services cannot enable end to end encryption"
                                            matTooltipPosition="below"
                                        ></div>
                                    </div>
                                    <div [ngClass]="{ error: errors['serverHostName'] }" class="label">
                                        Host Name / IP
                                        <div
                                            class="infoicon"
                                            matTooltip="The provided hostname/ip must be addressable from the selected host."
                                            matTooltipPosition="below"
                                        ></div>
                                    </div>
                                    <div [ngClass]="{ error: errors['serverPort'] }" class="label">Port</div>
                                    <div>
                                        <select
                                            (change)="protocolSelection($event)"
                                            [(ngModel)]="model.model.serverEgress.protocol"
                                            [disabled]="isEditing && !canEdit"
                                            [ngClass]="{ error: errors['protocol'] }"
                                            id="ConfigRouterProtocol"
                                        >
                                            <option [ngValue]="undefined">Protocol Type</option>
                                            <option value="tcp">TCP</option>
                                            <option value="udp">UDP</option>
                                        </select>
                                        <span [hidden]="!errors['egressProtocolError']" class="err">
                                            {{ errors['egressProtocolError'] }}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            [(ngModel)]="model.model.serverEgress.host"
                                            [disabled]="isEditing && !canEdit"
                                            [ngClass]="{ error: errors['serverHostName'] }"
                                            id="ConfigRouterHostName"
                                            placeholder="host.name"
                                            (change)="validate()"
                                        />
                                        <span [hidden]="!errors['egressHostError']" class="err">
                                            {{ errors['egressHostError'] }}
                                        </span>
                                        <span [hidden]="!errors['erHostError']" class="err">
                                            {{ errors['erHostError'] }}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            [(ngModel)]="model.model.serverEgress.port"
                                            [disabled]="isEditing && !canEdit"
                                            [ngClass]="{ error: errors['serverPort'] }"
                                            id="ConfigRouterPort"
                                            placeholder="0"
                                            (change)="validate()"
                                            type="number"
                                        />
                                        <span [hidden]="!errors['egressPortError']" class="err">
                                            {{ errors['egressPortError'] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 1.25rem">
                <button (click)="save()" *ngIf="!processing && !isLoading" class="save" id="SaveServiceZitiButton">
                    {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="isLoading || processing" class="save confirm" disabled>
                    {{ isLoading ? 'Loading' : 'Saving' }}
                    <span class="spinner"></span>
                </button>
            </div>

            <div *ngIf="!isEditing" class="row" style="margin-top: 1.25rem">
                <div class="cardStack full pop" style="height: 36.5625rem; margin-top: 0rem; margin-bottom: 1.875rem">
                    <div class="hinttag">HINT</div>
                    <div class="hinttitle">THREE TYPES OF SERVICE HOSTING</div>
                    <div class="row">
                        <div class="pod">
                            <div class="icon type1"></div>
                            <div class="hintsubtitle">NATIVE SDK HOSTED</div>
                            <div class="hintsubsub">
                                Full Zero Trust - the ultimate in protection! Your data never leaves the address space
                                of your application unencrypted.
                            </div>
                        </div>
                        <div class="pod">
                            <div class="icon type2"></div>
                            <div class="hintsubtitle">
                                {{ featureService?.disableNewNavigation ? 'ENDPOINT' : 'IDENTITY' }} HOSTED
                            </div>
                            <div class="hintsubsub">
                                The service traffic leaves the Network at specified
                                {{ featureService?.disableNewNavigation ? 'endpoints' : 'identities' }}. This requires a
                                {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }}
                                running and enrolled.
                            </div>
                        </div>
                        <div class="pod">
                            <div class="icon type3"></div>
                            <div class="hintsubtitle">ROUTER HOSTED</div>
                            <div class="hintsubsub">
                                The service traffic leaves the Network at the Router. This requires a deployed router.
                            </div>
                        </div>
                    </div>
                    <div class="serviceexampleschart"></div>
                </div>
            </div>
        </div>
    </div>

    <div (click)="getMetrics()" *ngIf="isEditing" class="buttonBall metrics" id="MetricsButton">
        <div class="buttonLabel">METRICS</div>
    </div>
    <div (click)="getDialLogs()" *ngIf="isEditing" class="buttonBall network-failure" id="DialLogsButton">
        <div class="buttonLabel">Dial Logs</div>
    </div>

    <div (click)="hide()" class="buttonBall close" id="EscZitiServiceClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
