<div attr.name="CellOS_{{ item.id }}" class="tCol clickCol desktop" id="CellOS_{{ item.id }}">
    <span *ngIf="item.event_type === 'router-offline'" class="bubble severityError" style="margin-right: 0.3125rem"></span>
    <span
        *ngIf="item.event_type === 'created' || item.event_type === 'router-online'"
        class="bubble severityInfo"
        style="margin-right: 0.3125rem"
    ></span>
    <span *ngIf="item.event_type === 'deleted'" class="bubble v7Offline" style="margin-right: 0.3125rem"></span>
    <span *ngIf="item.event_type">{{ event_type }}</span>
</div>
