<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="ZitiAppWANAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="isEditing"
                >Edit Your {{ featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy' }}</span
            >
            <span *ngIf="!isEditing"
                >Create A New {{ featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy' }}</span
            >
        </div>
        <div class="subtitle">
            Enter your {{ featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy' }} details
        </div>
        <div
            class="subhelp"
            matTooltip="{{
                featureService.disableNewNavigation ? 'AppWANs are the policies which' : 'Service Policies'
            }} determine which services your {{
                featureService.disableNewNavigation ? 'endpoints' : 'identities'
            }} have permission to access.  This gives you zero trust, least privileged access, at the most granular levels."
            matTooltipPosition="below"
        >
            What's {{ featureService.disableNewNavigation ? 'an AppWAN' : 'a Service Policy' }}?
        </div>
        <div class="sized full">
            <div class="grid half">
                <div>
                    <div class="label row">
                        {{ featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy' }} Name
                        <span [hidden]="errors['name']" class="note">Required</span>
                    </div>
                    <div class="row">
                        <input
                            (change)="validate()"
                            (keyup.enter)="save(true)"
                            [(ngModel)]="model.name"
                            [disabled]="isEditing && !canEdit"
                            [ngClass]="{ error: errors['name'] }"
                            id="EditName"
                            placeholder="Name this {{
                                featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy'
                            }}"
                            type="text"
                        />
                        <span [hidden]="!errors['name']" class="err">
                            {{ errors['name'] }}
                        </span>
                    </div>
                    <div class="label row">
                        Service Attributes
                        <div
                            class="infoicon"
                            matTooltip="Associates the provided services and service groups to this {{
                                featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy'
                            }}."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row service-attributes">
                        <app-tags-selector
                            (addAttribute)="addServiceAttribute($event)"
                            (removeAttribute)="removeServiceAttribute($event)"
                            [availableAttributes]="serviceAttributes"
                            [disableField]="isEditing && !canEdit"
                            [errorMessage]="errors['serviceAttributes']"
                            [hasError]="serviceAttributeError"
                            [isLoading]="isLoadingSvcAttr"
                            [placeholder]="
                                'Choose the services to associate to this ' +
                                (featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy') +
                                ''
                            "
                            [selectedAttributes]="selectedServiceAttributes"
                            id="Service"
                        >
                        </app-tags-selector>
                    </div>
                    <div class="label row">
                        {{ featureService.disableNewNavigation ? 'Endpoint' : 'Identity' }} Attributes
                        <div
                            class="infoicon"
                            matTooltip="Associates the provided {{
                                featureService.disableNewNavigation ? 'endpoints' : 'identities'
                            }} and {{ featureService.disableNewNavigation ? 'endpoint' : 'identity' }} groups to this {{
                                featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy'
                            }}."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row endpoint-attributes">
                        <app-tags-selector
                            (addAttribute)="addEndpointsAttributes($event)"
                            (removeAttribute)="removeEndpointAttribute($event)"
                            [availableAttributes]="endpointAttributes"
                            [disableField]="isEditing && !canEdit"
                            [errorMessage]="errors['endpointAttributes']"
                            [hasError]="epAttributeError"
                            [isLoading]="isLoadingEndpointAttr"
                            placeholder="Choose the {{
                                featureService.disableNewNavigation ? 'endpoints' : 'identities'
                            }} to associate to this {{
                                featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy'
                            }}"
                            [selectedAttributes]="selectedEndpointAttributes"
                            id="Endpoint"
                        ></app-tags-selector>
                    </div>
                    <div class="label row">
                        Posture Attributes
                        <div
                            class="infoicon"
                            matTooltip="Associates the provided posture checks and posture groups to this {{
                                featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy'
                            }}."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row">
                        <app-tags-selector
                            (addAttribute)="addPostureCheckAttribute($event)"
                            (removeAttribute)="removePostureCheckAttribute($event)"
                            [availableAttributes]="postureCheckAttributes"
                            [disableField]="isEditing && !canEdit"
                            [errorMessage]="errors['postureCheckAttributes']"
                            [hasError]="pcAttributeError"
                            [hideOption]="showMFAEnabled ? '@zitimf' : undefined"
                            [isLoading]="isLoadingPostureCheckAttr"
                            [placeholder]="
                                'Choose the posture checks to associate to this ' +
                                (featureService.disableNewNavigation ? 'AppWAN' : 'Service Policy') +
                                ''
                            "
                            [selectedAttributes]="selectedPostureCheckAttributes"
                            id="PostureCheck"
                        ></app-tags-selector>
                    </div>
                </div>
                <div>
                    <div [ngClass]="{ 'preview-list-mfa': showMFAEnabled }" class="grid three entity-preview-list">
                        <app-preview-list
                            [allNames]="servicePreviewList"
                            [isLoading]="isServicesPreview"
                            id="ServicesPreview"
                            label="Services Preview"
                            (displayPostProcess)="postProcessDisplay($event)"
                        ></app-preview-list>
                        <app-preview-list
                            [allNames]="v2Endpoints"
                            [isLoading]="isEndpointsPreview"
                            id="EndpointsPreview"
                            label="{{ featureService.disableNewNavigation ? 'Endpoints' : 'Identities' }} Preview"
                        ></app-preview-list>
                        <app-preview-list
                            [allNames]="v2PostureChecks"
                            [hideOption]="mfaEnabled ? 'zitimfa' : undefined"
                            [isLoading]="isPostureCheckPreview"
                            label="Posture Preview"
                        ></app-preview-list>
                    </div>
                    <div
                        *ngIf="showMFAEnabled"
                        class="grid"
                        style="padding-top: 0.5rem; width: 75%; float: right; padding-bottom: 0.3125rem"
                    >
                        <div>
                            <div class="label row">
                                Multi Factor Authentication
                                <span class="note">Optional</span>
                            </div>
                            <div class="row">
                                <div class="encryptionpod">
                                    <img
                                        src="/assets/svgs/MFA.svg"
                                        style="height: 2.8125rem; padding-top: 0.1875rem; vertical-align: top; padding-left: 0.125rem"
                                    />
                                    <span class="encryptiontext" style="padding-bottom: 1.25rem">ENABLE MFA</span>
                                    <div
                                        (click)="mfaEnableToggle()"
                                        [ngClass]="{ on: mfaEnabled }"
                                        class="toggle"
                                        style="float: right; margin-top: 0.9375rem; margin-right: 0.75rem"
                                    >
                                        <span [hidden]="!mfaEnabled" style="margin-left: -0.9375rem">YES</span>
                                        <span [hidden]="mfaEnabled" style="margin-right: -0.9375rem">NO</span>
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isEditing || canEdit" class="row buttonRow right">
                <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 1.25rem">
                    <button (click)="save()" *ngIf="!processing && !isLoading" class="save" id="SaveAppWANZitiButton">
                        {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                    </button>
                    <button *ngIf="isLoading || processing" class="save confirm" disabled>
                        {{ isLoading ? 'Loading' : 'Saving' }}
                        <span class="spinner"></span>
                    </button>
                </div>
            </div>
        </div>

        <div (click)="hide()" class="buttonBall close" id="EscZitiAWClose">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    </div>
</div>
