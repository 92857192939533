import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-elk-visualization',
    templateUrl: './elk-visualization.component.html',
    styleUrls: ['./elk-visualization.component.scss'],
    providers: [TobytesPipe, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }],
})
export class ElkVisualizationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sourceId: string;
    @Input() networkId: any = null;
    @Input() organizationId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() height = '300px';
    chart: Chart;
    colors = ['#2f7ed8', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];
    noData = true;
    isLoading = false;
    initialized = false;
    currentOrgId;
    identity_options = {};
    identity_series = [];
    interval = '1m';
    @Input() filterField = null;
    @Input() filterValue = null;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private graphViewer: MatDialog,
        public apiService: ApiService
    ) {}

    ngOnInit() {
        this.initialized = true;
        this.generateUniqueIdentityData();
    }

    ngOnChanges() {
        this.logger.info('Changes...');
        this.generateUniqueIdentityData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getUniqueIdentities() {
        const index = 'ncutilization';
        const IdentityCount = [];

        this.logger.info('Ziti Unique Identity Query:', JSON.stringify(this.getQuery()));

        this.noData = true;
        this.isLoading = true;
        //reporting
        const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = {
            gte: 'now-' + this.dateFilter,
            lte: 'now',
            size: '0',
            timeZone: momentTz.tz.guess(),
            interval: this.determine_interval(),
            networkId: this.networkId,
            networkGroupId: this.organizationId,
            indexName: index,
        };

        this.subscription.add(
            //this.elasticsearch.search(this.organizationId, index, this.getQuery())
            this.elasticsearch
                .apiTemplateSearch('ncutilization_active_endpoints_tmpl', networkUtilizationTemplateQueryParameter)
                .subscribe(
                    (data) => {
                        this.logger.info(data);
                        const agData = _.get(data, 'aggregations.date_buckets');
                        if (!agData || !agData.buckets) {
                            this.logger.info('no datebuckets returned for identity data');
                            this.noData = true;
                            this.isLoading = false;
                            return;
                        }

                        this.logger.info('Identity Query', JSON.stringify(this.getQuery()));

                        for (const bucket of agData.buckets) {
                            const timestamp = moment(bucket['key_as_string']).unix() * 1000;

                            const count = _.get(bucket, 'unique_identities.value');

                            IdentityCount.push([timestamp, count]);
                        }

                        this.set_chart_options();
                        this.identity_series = [
                            {
                                name: 'Active Endpoints',
                                data: IdentityCount,
                                color: '#0273fb',
                            },
                        ];

                        if (agData.buckets.length > 0) {
                            this.noData = false;
                        }
                        this.isLoading = false;
                    },
                    () => {
                        this.isLoading = false;
                    }
                )
        );
    }

    public set_chart_options() {
        const startTime = moment().subtract(
            this.dateFilter.slice(0, -1),
            this.dateFilter.slice(this.dateFilter.length - 1, this.dateFilter.length)
        );
        const endTime = moment().local();
        window.moment = moment;

        // utilization options
        // const pipe = this.toBytes;
        this.identity_options = {
            time: {
                timezone: momentTz.tz.guess(),
            },
            colors: this.colors,
            styledMode: true,
            title: { text: null },
            xAxis: {
                type: 'datetime',
                // the max tick number
                max: endTime.valueOf(),
                // the min tick number
                min: startTime.valueOf(),
            },
            credits: { enabled: false },
            chart: {
                // spacing: [10, 10, 0, 10],
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                height: this.height,
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    fillOpacity: 0.5,
                },
            },
            yAxis: {
                title: {
                    text: null,
                },
                min: 0,
                // tickPixelInterval: 20,
                labels: {
                    formatter: function () {
                        return this.value;
                    },
                    style: {
                        color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                    },
                },
            },
            legend: {
    
                margin: 0,
                itemStyle: {
                    color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                },
            },
            tooltip: {
                formatter: function () {
                    /* tslint:disable */
                    const d = new Date(this.x);
                    /* tslint:enable */
                    return d.toLocaleDateString() + '<br /><strong>' + this.y + '</strong>';
                },
            },
        };
    }

    public getQuery() {
        const model: any = {
            aggs: {
                date_buckets: {
                    date_histogram: {
                        field: 'timestamp',
                        interval: this.determine_interval(),
                        time_zone: 'UTC',
                        min_doc_count: 1,
                    },
                    aggs: {
                        unique_identities: {
                            cardinality: {
                                field: 'identity_id.keyword',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                timestamp: {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'network_id.keyword': {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'organizationId.keyword': {
                                    query: this.organizationId,
                                },
                            },
                        },
                    ],
                },
            },
        };
        return model;
    }

    private generateUniqueIdentityData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.organizationId !== null) {
            this.getUniqueIdentities();
        } else {
            this.logger.info('Missing networkId, skipping utilization metrics call');
        }
    }

    private determine_interval() {
        if (this.dateFilter.includes('m')) {
            return '1d';
        } else if (this.dateFilter.includes('d')) {
            return '1h';
        } else if (this.dateFilter.includes('h')) {
            return '10m';
        } else {
            // just in case...
            return '1d';
        }
    }
}
