<div class="fullModal opened" id="PostureCheckFormModal">
    <!-- Header Editor Buttons -->
    <div (click)="hide()" [hidden]="isLoading" class="buttonBall close" id="ClosePostureCheckButton">
        <div class="buttonLabel">ESC</div>
    </div>

    <app-loader [isLoading]="isLoading"></app-loader>

    <div [hidden]="isLoading" class="fullRow">
        <div [hidden]="isEditing" class="title" style="filter: brightness(50%)">Create A New Posture Check</div>
        <div [hidden]="!isEditing" class="title" style="filter: brightness(50%)">Edit Your Posture Check</div>
        <div class="subtitle">Configure a Device Posture Check</div>
        <div
            class="subhelp"
            matTooltip="A configurable condition that evaluates one or more aspects of an Endpoint before allowing a connection."
            matTooltipPosition="below"
        >
            What's a Posture Check?
        </div>
        <div class="sized mid">
            <div class="row">
                <div class="step">1</div>
                <div class="label row">
                    POSTURE Name
                    <span [hidden]="errors['name']" class="note">Required</span>
                </div>
                <div class="row">
                    <input
                        (change)="validate()"
                        [(ngModel)]="model.name"
                        [disabled]="isEditing && !canEdit"
                        [ngClass]="{ error: errors['name'] }"
                        id="PostureCheckName"
                        maxlength="64"
                        placeholder="Name this check"
                    />
                    <span [hidden]="!errors['name']" class="err">
                        {{ errors['name'] }}
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="step">2</div>
                <div class="label row">
                    Posture Attributes
                    <div
                        class="infoicon"
                        matTooltip="Posture Attributes are tags applied to a posture check. Apply the same tag to other checks to form a group of posture checks."
                        matTooltipPosition="below"
                    ></div>
                </div>

                <div class="row">
                    <app-tags-selector
                        (addAttribute)="addPostureCheckAttribute($event)"
                        (removeAttribute)="removePostureCheckAttribute($event)"
                        [availableAttributes]="postureCheckAttributes"
                        [disableField]="isEditing && !canEdit"
                        [hasError]="attributeError"
                        [errorMessage]="errors['attributes']"
                        [isLoading]="isLoadingPostureCheckAttr"
                        [placeholder]="'Add attributes to group postures'"
                        [selectedAttributes]="selectedPostureCheckAttributes"
                        id="PostureCheck"
                    ></app-tags-selector>
                </div>
            </div>
            <div class="row">
                <div class="step">3</div>
                <div class="label row">
                    Select Posture Type
                    <div
                        class="infoicon"
                        matTooltip="A specific condition that evaluates one or more aspects of an Endpoint before allowing a connection."
                        matTooltipPosition="below"
                    ></div>
                    <span [ngClass]="{ error: errorPostureType }" class="note">Required</span>
                </div>
                <div class="row">
                    <div class="wizard">
                        <div class="row">
                            <select
                                [(ngModel)]="model.type"
                                [disabled]="isEditing"
                                [ngClass]="{ error: errorPostureType }"
                            >
                                <option [ngValue]="null">Select a posture type to view available OS</option>
                                <option *ngFor="let type of postureTypes" [value]="type.value">
                                    {{ type.name }}
                                </option>
                            </select>
                        </div>

                        <div *ngIf="model.type === 'MAC'" class="row">
                            <div class="grid split">
                                <div class="label row wizardLabel osLabel">Available For</div>
                                <div class="osIcons">
                                    <div (click)="(!isEditing || canEdit)" class="iconArea windowsOS"></div>
                                    <div (click)="(!isEditing || canEdit)" class="macOS"></div>
                                    <div (click)="(!isEditing || canEdit)" class="linuxOS"></div>
                                    <div (click)="(!isEditing || canEdit)" class="androidOS"></div>
                                </div>
                            </div>
                            <div class="label row wizardLabel">
                                MAC Address Verification
                                <div
                                    class="infoicon"
                                    matTooltip="Verify the device has the expected MAC addresses. If you have more than one MAC address, separate them using semicolons."
                                    matTooltipPosition="below"
                                ></div>
                                <span [ngClass]="{ error: errorMacAddress }" class="note">Required</span>
                            </div>
                            <div class="row">
                                <textarea
                                    [(ngModel)]="macAddressString"
                                    [disabled]="isEditing && !canEdit"
                                    [ngClass]="{ error: errorMacAddress }"
                                    id="MacAddress"
                                    (change)="validate()"
                                    placeholder="00:A0:C9:14:C8:29;00:11:22:33:44:55"
                                ></textarea>
                            </div>
                        </div>
                        <div *ngIf="model.type === 'OS'" class="row">
                            <div class="row label osSelect">
                                Select one or More OS
                                <div
                                    class="infoicon"
                                    matTooltip="Verify the device OS and or Version. If any version is acceptable leave the version fields blank"
                                    matTooltipPosition="below"
                                ></div>
                                <span [ngClass]="{ error: errorOsSelect }" class="note">Required</span>
                            </div>
                            <div class="row wizard inner padless">
                                <div class="grid split osSelect">
                                    <div class="wizard toggleWizard osToggle windowsOS">
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleWindows()"
                                            [ngClass]="{ on: windowsToggle }"
                                            class="toggle"
                                        >
                                            <span [hidden]="!windowsToggle" id="windowsToggleOn"
                                                >ON&nbsp;&nbsp;&nbsp;</span
                                            >
                                            <span [hidden]="windowsToggle" id="windowsToggleOff"
                                                >&nbsp;&nbsp;&nbsp;&nbsp;OFF</span
                                            >
                                            <div class="switch"></div>
                                        </div>
                                    </div>

                                    <div class="osVersionSelect">
                                        <div class="split grid version">
                                            <div>
                                                <div [ngClass]="{ error: errorWindowsMinVersion }" class="label row">
                                                    Min Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="windowsMinVersion"
                                                        [disabled]="!windowsToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorWindowsMinVersion }"
                                                        id="WindowsMinVersion"
                                                        placeholder="10.0.19041"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div [ngClass]="{ error: errorWindowsMaxVersion }" class="label row">
                                                    Max Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="windowsMaxVersion"
                                                        [disabled]="!windowsToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorWindowsMaxVersion }"
                                                        id="WindowsMaxVersion"
                                                        placeholder="optional"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row wizard inner padless">
                                <div class="grid split osSelect">
                                    <div class="wizard toggleWizard osToggle windowsServer">
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleWindowsServer()"
                                            [ngClass]="{ on: windowsServerToggle }"
                                            class="toggle"
                                        >
                                            <span [hidden]="!windowsServerToggle" id="windowsServerToggleOn"
                                                >ON&nbsp;&nbsp;&nbsp;</span
                                            >
                                            <span [hidden]="windowsServerToggle" id="windowsServerToggleOff"
                                                >&nbsp;&nbsp;&nbsp;&nbsp;OFF</span
                                            >
                                            <div class="switch"></div>
                                        </div>
                                    </div>

                                    <div class="osVersionSelect">
                                        <div class="split grid version">
                                            <div>
                                                <div
                                                    [ngClass]="{ error: errorWindowsServerMinVersion }"
                                                    class="label row"
                                                >
                                                    Min Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="windowsServerMinVersion"
                                                        [disabled]="!windowsServerToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorWindowsServerMinVersion }"
                                                        id="WindowsServerMinVersion"
                                                        placeholder="10.0.14393"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div
                                                    [ngClass]="{ error: errorWindowsServerMaxVersion }"
                                                    class="label row"
                                                >
                                                    Max Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="windowsServerMaxVersion"
                                                        [disabled]="!windowsServerToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorWindowsServerMaxVersion }"
                                                        id="WindowsServerMaxVersion"
                                                        placeholder="optional"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row wizard inner padless">
                                <div class="grid split osSelect">
                                    <div class="wizard toggleWizard osToggle macOS">
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleMac()"
                                            [ngClass]="{ on: macToggle }"
                                            class="toggle"
                                        >
                                            <span [hidden]="!macToggle" id="macToggleOn">ON&nbsp;&nbsp;&nbsp;</span>
                                            <span [hidden]="macToggle" id="macToggleOff"
                                                >&nbsp;&nbsp;&nbsp;&nbsp;OFF</span
                                            >
                                            <div class="switch"></div>
                                        </div>
                                    </div>

                                    <div class="osVersionSelect">
                                        <div class="split grid version">
                                            <div>
                                                <div [ngClass]="{ error: errorMacMinVersion }" class="label row">
                                                    Min Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="macMinVersion"
                                                        [disabled]="!macToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorMacMinVersion }"
                                                        id="MacMinVersion"
                                                        placeholder="10.15.7"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div [ngClass]="{ error: errorMacMaxVersion }" class="label row">
                                                    Max Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="macMaxVersion"
                                                        [disabled]="!macToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorMacMaxVersion }"
                                                        id="MacMaxVersion"
                                                        placeholder="optional"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row wizard inner padless">
                                <div class="grid split osSelect">
                                    <div class="wizard toggleWizard osToggle linuxOS">
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleLinux()"
                                            [ngClass]="{ on: linuxToggle }"
                                            class="toggle"
                                        >
                                            <span [hidden]="!linuxToggle" id="linuxToggleOn">ON&nbsp;&nbsp;&nbsp;</span>
                                            <span [hidden]="linuxToggle" id="linuxToggleOff"
                                                >&nbsp;&nbsp;&nbsp;&nbsp;OFF</span
                                            >
                                            <div class="switch"></div>
                                        </div>
                                    </div>

                                    <div class="osVersionSelect">
                                        <div class="split grid version">
                                            <div>
                                                <div [ngClass]="{ error: errorLinuxMinVersion }" class="label row">
                                                    Min Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="linuxMinVersion"
                                                        [disabled]="!linuxToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorLinuxMinVersion }"
                                                        id="LinuxMinVersion"
                                                        placeholder="2.6.32"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div [ngClass]="{ error: errorLinuxMaxVersion }" class="label row">
                                                    Max Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="linuxMaxVersion"
                                                        [disabled]="!linuxToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorLinuxMaxVersion }"
                                                        id="LinuxMaxVersion"
                                                        placeholder="optional"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row wizard inner padless">
                                <div class="grid split osSelect">
                                    <div class="wizard toggleWizard osToggle android">
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleAndroid()"
                                            [ngClass]="{ on: androidToggle }"
                                            class="toggle"
                                        >
                                            <span [hidden]="!androidToggle" id="androidToggleOn"
                                                >ON&nbsp;&nbsp;&nbsp;</span
                                            >
                                            <span [hidden]="androidToggle" id="androidToggleOff"
                                                >&nbsp;&nbsp;&nbsp;&nbsp;OFF</span
                                            >
                                            <div class="switch"></div>
                                        </div>
                                    </div>

                                    <div class="osVersionSelect">
                                        <div class="split grid version">
                                            <div>
                                                <div [ngClass]="{ error: errorAndroidMinVersion }" class="label row">
                                                    Min Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="androidMinVersion"
                                                        [disabled]="!androidToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorAndroidMinVersion }"
                                                        id="AndroidMinVersion"
                                                        placeholder="7.0.0"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div [ngClass]="{ error: errorAndroidMaxVersion }" class="label row">
                                                    Max Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="androidMaxVersion"
                                                        [disabled]="!androidToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: errorAndroidMaxVersion }"
                                                        id="AndroidMaxVersion"
                                                        placeholder="optional"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row wizard inner padless last">
                                <div class="grid split osSelect">
                                    <div class="wizard toggleWizard osToggle iOS">
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleiOS()"
                                            [ngClass]="{ on: iOSToggle }"
                                            class="toggle"
                                        >
                                            <span [hidden]="!iOSToggle" id="iOSToggleOn">ON&nbsp;&nbsp;&nbsp;</span>
                                            <span [hidden]="iOSToggle" id="iOSToggleOff"
                                                >&nbsp;&nbsp;&nbsp;&nbsp;OFF</span
                                            >
                                            <div class="switch"></div>
                                        </div>
                                    </div>

                                    <div class="osVersionSelect">
                                        <div class="split grid version">
                                            <div>
                                                <div [ngClass]="{ error: erroriOSMinVersion }" class="label row">
                                                    Min Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="iOSMinVersion"
                                                        [disabled]="!iOSToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: erroriOSMinVersion }"
                                                        id="iOSMinVersion"
                                                        placeholder="10.3.4"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div [ngClass]="{ error: erroriOSMaxVersion }" class="label row">
                                                    Max Version
                                                </div>
                                                <div class="row">
                                                    <input
                                                        [(ngModel)]="iOSMaxVersion"
                                                        [disabled]="!iOSToggle || (isEditing && !canEdit)"
                                                        [disabled]="isEditing && !canEdit"
                                                        [ngClass]="{ error: erroriOSMaxVersion }"
                                                        id="iOSMaxVersion"
                                                        placeholder="optional"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="model.type === 'DOMAIN'" class="row">
                            <div class="grid split">
                                <div class="label row wizardLabel osLabel">Available For</div>
                                <div class="osIcons">
                                    <div class="iconArea windowsOS"></div>
                                </div>
                            </div>
                            <div class="label row wizardLabel">
                                Domain
                                <div
                                    class="infoicon"
                                    matTooltip="Verify the device's OS has joined a specific domain/workgroup."
                                    matTooltipPosition="below"
                                ></div>
                                <span [ngClass]="{ error: errorDomain }" class="note">Required</span>
                            </div>
                            <div class="row">
                                <textarea
                                    [(ngModel)]="domainString"
                                    [disabled]="isEditing && !canEdit"
                                    [ngClass]="{ error: errorDomain }"
                                    (change)="validate()"
                                    id="Domain"
                                    placeholder="Enter one or more Domains or Workgroups. If you have more than one domain, separate them using semicolons."
                                ></textarea>
                            </div>
                        </div>
                        <div *ngIf="model.type === 'MFA'" class="row">
                            <div>
                                <div class="label row wizardLabel">Optional MFA Settings</div>
                            </div>
                            <div class="encryptionpod">
                                <span class="encryptiontext">MFA RE-CHECK ON WAKE</span>
                                <div
                                    class="infoicon"
                                    matTooltip="Force endpoints on laptops/desktop systems to enter a valid MFA code on wake. Failure to do so will disconnect the endpoint from the associated AppWan's services."
                                    matTooltipPosition="below"
                                ></div>
                                <div
                                    (click)="toggleWake()"
                                    [ngClass]="{ on: promptOnWake }"
                                    class="toggle togglePostureCheck"
                                >
                                    <span [hidden]="!promptOnWake" style="margin-left: -0.9375rem">YES</span>
                                    <span [hidden]="promptOnWake" style="margin-right: -0.9375rem">NO</span>
                                    <div class="switch"></div>
                                </div>
                            </div>
                            <div class="encryptionpod" style="margin-top: 0.9375rem">
                                <span class="encryptiontext">MFA RE-CHECK ON UNLOCK</span>
                                <div
                                    class="infoicon"
                                    matTooltip="Force endpoints to enter a valid MFA code when unlocked. Failure to do so will disconnect the endpoint from the associated AppWan's services."
                                    matTooltipPosition="below"
                                ></div>
                                <div
                                    (click)="toggleUnlock()"
                                    [ngClass]="{ on: promptOnUnlock }"
                                    class="toggle togglePostureCheck"
                                >
                                    <span [hidden]="!promptOnUnlock" style="margin-left: -0.9375rem">YES</span>
                                    <span [hidden]="promptOnUnlock" style="margin-right: -0.9375rem">NO</span>
                                    <div class="switch"></div>
                                </div>
                            </div>
                            <div class="encryptionpod timeoutPod" style="margin-top: 0.9375rem">
                                <span class="encryptiontext">MFA RE-CHECK DURATION</span>
                                <div
                                    class="infoicon"
                                    matTooltip="Force endpoints to enter a valid MFA code periodically. Failure to do so will disconnect the endpoint from the associated AppWan's services."
                                    matTooltipPosition="below"
                                ></div>
                                <div
                                    (click)="toggleDuration()"
                                    [ngClass]="{ on: hasTimeoutLimit }"
                                    class="toggle togglePostureCheck"
                                >
                                    <span [hidden]="!hasTimeoutLimit" style="margin-left: -0.9375rem">YES</span>
                                    <span [hidden]="hasTimeoutLimit" style="margin-right: -0.9375rem">NO</span>
                                    <div class="switch"></div>
                                </div>
                                <div *ngIf="hasTimeoutLimit" class="timeoutContainer">
                                    <div class="timeoutBox">
                                        <label class="timeoutText">Hours</label>
                                        <input [(ngModel)]="timeoutHours" type="text" value="0" />
                                    </div>
                                    <div class="timeoutBox">
                                        <label class="timeoutText">Minutes</label>
                                        <input [(ngModel)]="timeoutMinutes" type="text" value="0" />
                                    </div>
                                </div>
                            </div>
                            <div class="encryptionpod" style="margin-top: 0.9375rem">
                                <span class="encryptiontext">ALLOW UNSUPPORTED ENDPOINTS TO CONNECT</span>
                                <div
                                    class="infoicon"
                                    matTooltip="Allows endpoints that do not support MFA timeouts to remain connected past the timeout."
                                    matTooltipPosition="below"
                                ></div>
                                <div
                                    (click)="toggleLegacyEndpoints()"
                                    [ngClass]="{ on: ignoreLegacyEndpoints }"
                                    class="toggle togglePostureCheck"
                                >
                                    <span [hidden]="!ignoreLegacyEndpoints" style="margin-left: -0.9375rem">YES</span>
                                    <span [hidden]="ignoreLegacyEndpoints" style="margin-right: -0.9375rem">NO</span>
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="model.type === 'PROCESS'" class="row">
                            <div class="grid split">
                                <div class="label row wizardLabel osLabel">Select an OS</div>
                                <div class="osIcons">
                                    <div
                                        (click)="(!isEditing || canEdit) && selectOs('Windows')"
                                        [ngClass]="{ selected: processOs === 'Windows' }"
                                        class="iconArea windowsOS"
                                    ></div>
                                    <div
                                        (click)="(!isEditing || canEdit) && selectOs('macOS')"
                                        [ngClass]="{ selected: processOs === 'macOS' }"
                                        class="macOS"
                                    ></div>
                                    <div
                                        (click)="(!isEditing || canEdit) && selectOs('Linux')"
                                        [ngClass]="{ selected: processOs === 'Linux' }"
                                        class="linuxOS"
                                    ></div>
                                </div>
                            </div>

                            <div class="label row wizardLabel">
                                Process Path
                                <div
                                    class="infoicon"
                                    matTooltip="Verify the expected process is running with specific binary sha512 (i.e. for EDR software) or a signing certificate."
                                    matTooltipPosition="below"
                                ></div>
                                <span [ngClass]="{ error: errorProcessPath }" class="note">Required</span>
                            </div>
                            <div class="row">
                                <input
                                    [(ngModel)]="processPath"
                                    [disabled]="isEditing && !canEdit"
                                    [ngClass]="{ error: errorProcessPath }"
                                    id="ProcessCheckPath"
                                    placeholder="e.g, c:\folder\process.exe"
                                />
                            </div>
                            <div class="label row wizardLabel" style="margin-top: 0.9375rem">
                                Binary Verification - Choose One or Both
                            </div>
                            <div class="row">
                                <div class="wizard inner">
                                    <div class="label firstLabel row wizardLabel">
                                        Define Hash
                                        <div
                                            class="infoicon"
                                            matTooltip="Windows, macOS, Linux: The expected hash of the executable provided in Process Path (above)."
                                            matTooltipPosition="below"
                                        ></div>
                                        <span [ngClass]="{ error: errorHash }" class="note">Optional</span>
                                    </div>
                                    <div class="row">
                                        <textarea
                                            [(ngModel)]="hash"
                                            [disabled]="isEditing && !canEdit"
                                            [ngClass]="{ error: errorHash }"
                                            class="restrictNormal"
                                            id="hash"
                                            placeholder="sha512"
                                            (change)="validate()"
                                        ></textarea>
                                    </div>
                                    <div class="label row wizardLabel">
                                        Signer Certificate Thumbprint
                                        <div
                                            class="infoicon"
                                            matTooltip="Windows, macOS: The thumbprint of the certificate which signed the executable provided in Process Path (above)."
                                            matTooltipPosition="below"
                                        ></div>
                                        <span class="note">Optional</span>
                                    </div>
                                    <div class="row">
                                        <input
                                            [(ngModel)]="signerCertificateThumbprint"
                                            [disabled]="isEditing && !canEdit"
                                            id="signerCertificateThumbprint"
                                            placeholder="Enter the signature thumbprint"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="model.type === 'PROCESS_MULTI'">
                            <div
                                *ngFor="let process of model.data.processes; let i = index"
                                [attr.data-index]="i"
                                class="row mini-pod"
                            >
                                <div class="grid split">
                                    <div class="label row wizardLabel osLabel">Select an OS</div>

                                    <div class="osIcons">
                                        <div
                                            (click)="
                                                (!isEditing || canEdit) && selectMultiProcessOS(process, 'Windows')
                                            "
                                            [ngClass]="{ selected: process.osType === 'Windows' }"
                                            class="iconArea windowsOS"
                                        ></div>

                                        <div
                                            (click)="(!isEditing || canEdit) && selectMultiProcessOS(process, 'macOS')"
                                            [ngClass]="{ selected: process.osType === 'macOS' }"
                                            class="macOS"
                                        ></div>

                                        <div
                                            (click)="(!isEditing || canEdit) && selectMultiProcessOS(process, 'Linux')"
                                            [ngClass]="{ selected: process.osType === 'Linux' }"
                                            class="linuxOS"
                                        ></div>
                                    </div>
                                </div>

                                <div class="label row wizardLabel">
                                    Process Path
                                    <div
                                        class="infoicon"
                                        matTooltip="Verify the expected process is running with specific binary sha512 (i.e. for EDR software) or a signing certificate."
                                        matTooltipPosition="below"
                                    ></div>
                                    <span [ngClass]="{ error: errorProcessPath }" class="note">Required</span>
                                </div>

                                <div class="row">
                                    <input
                                        [(ngModel)]="process.path"
                                        [disabled]="isEditing && !canEdit"
                                        [ngClass]="{ error: errorProcessPath }"
                                        id="ProcessCheckPath"
                                        placeholder="e.g, c:\folder\process.exe"
                                    />
                                </div>

                                <div class="label row wizardLabel" style="margin-top: 1.25rem">
                                    Binary Verification - Choose One or Both
                                    <div (click)="expandProcessCheck(i)" *ngIf="expanded[i]">
                                        <div class="opener"></div>
                                    </div>

                                    <div (click)="expandProcessCheck(i)" *ngIf="!expanded[i]">
                                        <div class="opener open"></div>
                                    </div>
                                </div>

                                <div *ngIf="expanded[i]" class="row">
                                    <div>
                                        <div class="label firstLabel row wizardLabel">
                                            Define Hash
                                            <div
                                                class="infoicon"
                                                matTooltip="Windows, macOS, Linux: The expected hash of the executable provided in Process Path (above)."
                                                matTooltipPosition="below"
                                            ></div>
                                            <span [ngClass]="{ error: errorHash }" class="note">Optional</span>
                                        </div>

                                        <div class="row">
                                            <textarea
                                                [(ngModel)]="multiHashes[i]"
                                                [disabled]="isEditing && !canEdit"
                                                [ngClass]="{ error: errorHash }"
                                                class="restrictNormal"
                                                id="hash"
                                                placeholder="sha512"
                                                (change)="validate()"
                                            ></textarea>
                                        </div>

                                        <div class="label row wizardLabel">
                                            Signer Certificate Thumbprint
                                            <div
                                                class="infoicon"
                                                matTooltip="Windows, macOS: The thumbprint of the certificate which signed the executable provided in Process Path (above)."
                                                matTooltipPosition="below"
                                            ></div>
                                            <span class="note">Optional</span>
                                        </div>

                                        <div class="row">
                                            <textarea
                                                [(ngModel)]="multiSignerFingerprints[i]"
                                                [disabled]="isEditing && !canEdit"
                                                id="signerCertificateThumbprint"
                                                placeholder="Enter the signature thumbprint"
                                                (change)="validate()"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    (click)="deleteProcessCheck(i)"
                                    *ngIf="model.data.processes.length > 1"
                                    class="remove"
                                >
                                    <div class="icon-HamburgerAppClose"></div>
                                </button>
                            </div>

                            <button (click)="addAnotherProcessCheck()" class="save another">ADD ANOTHER PROCESS</button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 1.25rem">
                <button (click)="save()" *ngIf="!processing" class="save" id="SavePostureCheck">
                    {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
        <div class="fullrow">
            <div class="cardStack pop">
                <div class="hinttag">HINT</div>
                <div class="hinttitle">POSTURE TYPES AND AVAILABLE OS</div>
                <div class="hintsubtitle">
                    Not all posture types are available for all OS. After selecting posture types, you will see a list
                    of the available OS.
                </div>
            </div>
        </div>
    </div>
</div>
