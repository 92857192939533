<div class="modal solid open">
    <div class="fullColor">
        <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
        <div *ngIf="endpoint" class="title">{{ endpoint.name }} Events</div>
        <div *ngIf="edgeRouter" class="title">{{ edgeRouter.name }} Events</div>
        <div class="filterPod" style="width: 330px; display: inline-block">
            <mat-chip-listbox aria-label="DatePicker">
                <mat-chip-option (click)="setStartTime('1h')" [selected]="dateFilter === '1h'">1h</mat-chip-option>
                <mat-chip-option (click)="setStartTime('6h')" [selected]="dateFilter === '6h'">6h</mat-chip-option>
                <mat-chip-option (click)="setStartTime('24h')" [selected]="dateFilter === '24h'">24h</mat-chip-option>
                <mat-chip-option (click)="setStartTime('3d')" [selected]="dateFilter === '3d'">3d</mat-chip-option>
                <mat-chip-option (click)="setStartTime('7d')" [selected]="dateFilter === '7d'">7d</mat-chip-option>
                <mat-chip-option (click)="setStartTime('30d')" [selected]="dateFilter === '30d'">30d</mat-chip-option>
            </mat-chip-listbox>
        </div>
        <div class="cardBox">
            <div class="col-sm-6 pl-0">
                <div class="cardStack" style="height: 31.25rem">
                    <!-- Mop Event Timeline -->
                    <app-mop-timeline
                        [edgeRouterId]="edgeRouter?.nfId || edgeRouter?.id"
                        [endTime]="endTime"
                        [endpointId]="endpoint?.id"
                        [networkId]="currentNetwork.id"
                        [startTime]="startTime"
                    >
                    </app-mop-timeline>
                </div>
            </div>
            <div class="col-sm-6 mb24 pl-0">
                <div class="cardStack" style="height: 31.25rem">
                    <app-network-metrics-events-dash
                        [dateFilter]="dateFilter"
                        [edgeRouterId]="edgeRouter?.id"
                        [endpointId]="endpoint?.id"
                        [networkId]="currentNetwork.id"
                    >
                    </app-network-metrics-events-dash>
                </div>
            </div>
        </div>
    </div>
</div>
