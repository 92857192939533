<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="ZitiRouterPolicyAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="isEditing">Edit Your Edge Router Policy</span>
            <span *ngIf="!isEditing">Create A New Edge Router Policy</span>
        </div>
        <div class="subtitle">Enter your policy details</div>
        <div
            class="subhelp"
            matTooltip="Edge Router Policies specify the Edge Routers which {{
                featureService?.disableNewNavigation ? 'Endpoints' : 'Identities'
            }} are allowed to connect to. An {{
                featureService?.disableNewNavigation ? 'Endpoint' : 'Identity'
            }} MUST be able to connect to at least one Edge Router in order to participate in a NetFoundry Network. The best practice is to group all public routers using #public and create a #public routers/#all {{
                featureService?.disableNewNavigation ? 'endpoints' : 'identities'
            }} policy."
            matTooltipPosition="below"
        >
            What's an Edge Router Policy?
        </div>
        <div class="sized full">
            <div class="grid half">
                <div>
                    <div class="label row">
                        Policy Name
                        <span [hidden]="errors['name']" class="note">Required</span>
                    </div>
                    <div class="row">
                        <input
                            (change)="validate()"
                            (keyup.enter)="save()"
                            [(ngModel)]="model.name"
                            [disabled]="isEditing && !canEdit"
                            [ngClass]="{ error: errors['name'] }"
                            id="EditName"
                            placeholder="Name this policy"
                            type="text"
                        />
                        <span [hidden]="!errors['name']" class="err">
                            {{ errors['name'] }}
                        </span>
                    </div>

                    <div class="label row">
                        Edge Router Attributes
                        <div
                            class="infoicon"
                            matTooltip="Associates the provided edge routers and edge router groups to this policy."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row">
                        <app-tags-selector
                            (addAttribute)="addEdgeRouterAttribute($event)"
                            (removeAttribute)="removeEdgeRouterAttribute($event)"
                            [availableAttributes]="edgeRouterAttributes"
                            [disableField]="isEditing && !canEdit"
                            [errorMessage]="errors['edgeRouterAttributes']"
                            [hasError]="erAttributeError"
                            [isLoading]="isLoadingEdgeRouterAttr"
                            [placeholder]="'Choose edge routers to associate to this policy'"
                            [selectedAttributes]="selectedEdgeRouterAttributes"
                            id="EdgeRouterAttributes"
                        ></app-tags-selector>
                    </div>

                    <div class="label row">
                        {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }} Attributes
                        <div
                            class="infoicon"
                            matTooltip="Associates the provided {{
                                featureService?.disableNewNavigation ? 'endpoint' : 'identity'
                            }} and {{
                                featureService?.disableNewNavigation ? 'endpoint' : 'identity'
                            }} groups to this policy."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row">
                        <app-tags-selector
                            (addAttribute)="addEndpointAttribute($event)"
                            (removeAttribute)="removeEndpointAttribute($event)"
                            [availableAttributes]="endpointAttributes"
                            [disableField]="isEditing && !canEdit"
                            [errorMessage]="errors['endpointAttributes']"
                            [hasError]="epAttributeError"
                            [isLoading]="isLoadingEndpointAttr"
                            [placeholder]="
                                'Choose ' +
                                (featureService?.disableNewNavigation ? 'endpoints' : 'identities') +
                                ' to associate to this policy'
                            "
                            [selectedAttributes]="selectedEndpointAttributes"
                            id="EndpointAttributes"
                        ></app-tags-selector>
                    </div>
                </div>
                <div>
                    <div class="grid half thin">
                        <app-preview-list
                            [allNames]="edgeRouters"
                            [isLoading]="isEdgeRouterPreview"
                            label="Edge Routers Preview"
                        ></app-preview-list>
                        <app-preview-list
                            [allNames]="v2Endpoints"
                            [isLoading]="isEndpointsPreview"
                            label="{{ featureService?.disableNewNavigation ? 'Endpoints' : 'Identities' }} Preview"
                        ></app-preview-list>
                    </div>
                </div>
            </div>

            <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 1.25rem">
                <button (click)="save()" *ngIf="!processing && !isLoading" class="save" id="SavePolicyZitiButton">
                    {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="isLoading || processing" class="save confirm" disabled>
                    {{ isLoading ? 'Loading' : 'Saving' }}
                    <span class="spinner"></span>
                </button>
            </div>
        </div>
    </div>

    <div (click)="hide()" class="buttonBall close" id="EscZitiPolicyClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
