<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="ClientAddModal">
    <div [hidden]="isComplete" class="fullRow" id="Stage1">
        <div class="title" style="filter: brightness(50%)">Create A New Client</div>
        <div class="subtitle">Enter your client attributes</div>
        <div class="sized">
            <div class="label row">
                Client Name
                <span [hidden]="!errorNameLength" class="error">Name must be at least 5 characters</span>
                <span [hidden]="errorNameLength" class="note">Required</span>
            </div>
            <div class="row">
                <input
                    (keyup.enter)="save()"
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errorName }"
                    class="restrictNormal"
                    id="EditName"
                    maxlength="64"
                    minlength="5"
                    placeholder="What would you like to call this client?"
                    type="text"
                />
            </div>
            <div *ngIf="zitiEnabledService.zitiEnabled && !zitiEnabledService.zitiPure">
                <div class="label row edge">
                    Select Client Type<span [hidden]="!errorType" class="error">Input Required</span
                    ><span [hidden]="errorType" class="note">Required</span>
                </div>
                <select
                    (keyup.enter)="save()"
                    [(ngModel)]="model.endpointType"
                    [ngClass]="{ error: errorType }"
                    id="TypeList"
                >
                    <option value="">Standard or Ziti Capable</option>
                    <option value="ZTCL">Ziti Capable</option>
                    <option value="CL">Standard</option>
                </select>
            </div>
            <!-- <div class="label row edge">Location<span class="error" [hidden]="!errorRegion">Input Required</span><span class="note" [hidden]="errorRegion">Required</span></div>
<select id="RegionList" [ngClass]="{error:errorRegion}" [(ngModel)]="model.geoRegionId" (keyup.enter)="save()">
<option value="">Select A Region</option>
<option *ngFor="let region of regions | sortby: 'name'" [value]="region.id">{{ region.name }}</option>
</select> -->

            <app-country-list [errorCountry]="errorRegion || errorCountry" [model]="model"></app-country-list>

            <div *ngIf="canCreateUsers || canListUsers" class="row">
                <div class="label row labelExpansion">Require NF Auth Session</div>
                <div class="row expansion">
                    <mat-accordion>
                        <mat-expansion-panel #panel [expanded]="requireAuthSession" [hideToggle]="true">
                            <mat-expansion-panel-header (click)="toggleRequireAuth()" [collapsedHeight]="'4.0625rem'">
                                <mat-panel-title class="expansionLabel">
                                    <div class="title">
                                        <div class="icon-NFAuthorizationSessionManagement"></div>
                                    </div>
                                    <div class="subtitle">Require NF Auth Session</div>
                                    <div>
                                        <div class="switchGrid">
                                            <div>
                                                <div [ngClass]="{ on: requireAuthSession }" class="toggle">
                                                    <span [hidden]="!requireAuthSession">ON&nbsp;&nbsp;&nbsp;</span>
                                                    <span [hidden]="requireAuthSession">&nbsp;&nbsp;&nbsp;OFF</span>
                                                    <div class="switch"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-grid-list *ngIf="requireAuthSession" cols="10" rowHeight="6.25rem">
                                <mat-grid-tile [colspan]="3">
                                    <div [ngClass]="{ error: errorAuthUser }" class="label gridTileLabel">
                                        Select a User
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="7">
                                    <div style="width: 100%">
                                        <app-nf-select2
                                            (selectionChange)="onChangeAuthUserIdentityId($event)"
                                            [hasError]="errorAuthUser"
                                            [isFullWidth]="true"
                                            [options]="users"
                                            [placeholder]="'Select a User'"
                                            [selectOne]="true"
                                            [selected]="userSelected"
                                            [usingCustomSort]="true"
                                            id="UserSelector"
                                        ></app-nf-select2>
                                    </div>
                                    <!--<select class="expansionPanelField" id="UserList" [ngClass]="{error:errorAuthUser}" [(ngModel)]="authUserIdentity" (keyup.enter)="save()" (change)="onChangeAuthUserIdentityId()">
<option value="">Select a User</option>
<option value="{{newUserString}}">Add a New User</option>
<option *ngFor="let user of users | sortby: 'lastName'" [value]="user.id">{{ user.firstName }} {{ user.lastName }} - {{ user.email }}</option>
</select>-->
                                </mat-grid-tile>
                                <mat-grid-tile *ngIf="addingNewUser" [colspan]="3">
                                    <div [ngClass]="{ error: errorEmail }" class="label gridTileLabel">
                                        Enter Email Address
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile *ngIf="addingNewUser" [colspan]="7">
                                    <input
                                        [(ngModel)]="newUserEmail"
                                        [ngClass]="{ error: errorEmail }"
                                        class="expansionPanelField"
                                        id="EditEmail"
                                        placeholder="Enter an email address"
                                        type="text"
                                    />
                                </mat-grid-tile>
                            </mat-grid-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div *ngIf="model.geoRegionId" class="row buttonRow right" style="margin-top: 1.25rem">
                <button (click)="save()" *ngIf="!processing" class="save" id="SaveClientButton">Create</button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
    </div>
    <div [hidden]="!isComplete" class="fullRow" id="Stage2">
        <div class="title" style="filter: brightness(50%)">congratulations</div>
        <div class="subtitle">Your Client ({{ model.name }}) {{ completedTitleStatusString }}</div>
        <div [ngClass]="{ clientBuilding: isBuilding, clientCreated: !isBuilding }" class="row center">
            <span *ngIf="isBuilding" class="completedEndpointBubble">
                <svg class="circular" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        fill="none"
                        r="20"
                        stroke-miterlimit="10"
                        stroke-width="5"
                    ></circle>
                </svg>
            </span>
        </div>
        <div class="row edge">
            <div *ngIf="this.model.endpointType === 'CL' && !zitiEnabledService.zitiPure" class="sized">
                <div class="label row">Client Registration Key<span class="note">Click to Copy</span></div>
                <div class="row">
                    <input (click)="copy()" [(ngModel)]="regKey" class="RegKey" id="RegKey" readonly type="text" />
                </div>
            </div>
            <div *ngIf="this.model.endpointType === 'ZTCL' || zitiEnabledService.zitiPure" class="sized">
                <div class="label row">Client Registration Key<span class="note">Tap To Download</span></div>
                <div class="row">
                    <div (click)="downloadJWT()" class="downloadButton">
                        <div class="DownloadKey"></div>
                        <div>DOWNLOAD KEY</div>
                        <div class="TapToDownload"></div>
                    </div>
                </div>
                <div *ngIf="zitiRegKey">
                    <div class="label row" style="text-align: center">OR</div>
                    <div class="label row" style="text-align: center">Scan Client Registration Key QR Code</div>
                    <div class="row" style="left: 12.6875rem">
                        <qrcode [level]="'M'" [qrdata]="zitiRegKey" [size]="300"></qrcode>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="subtitle">Download and install instructions</div>
            <div class="row center padBottom">
                <div (click)="openDownloads()" class="buttonCard shadow openable">
                    <div class="gwdownloadicons" style="background-image: url(/assets/svgs/Icon_Download.svg)"></div>
                    <div class="title">Download Install Package</div>
                    <div class="content">Click to download the appropriate client for your operating system</div>
                    <div class="row center buttonArea">
                        <button class="save">Download</button>
                    </div>
                </div>
                <div (click)="openInstructions()" class="buttonCard shadow openable">
                    <div class="gwdownloadicons" style="background-image: url(/assets/svgs/Icon_Doc.svg)"></div>
                    <div class="title">View Install Instructions</div>
                    <div class="content">Follow these easy instructions to get your client up and running</div>
                    <div class="row center buttonArea">
                        <button class="save">View</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div (click)="hideForm()" class="buttonBall close" id="CloseClientButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div
        (click)="share()"
        *ngIf="authorizationService.canShareEndpoint(model.id)"
        [hidden]="!isComplete"
        class="buttonBall share"
        id="ShareClientButton"
    >
        <div class="buttonLabel">SHARE</div>
    </div>
    <div (click)="goBack()" [hidden]="isComplete" class="buttonBall back" id="BackClientButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
