<div class="fullModal opened" id="AutoFabricModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span>Create Your Edge Routers</span>
        </div>
        <div class="subtitle">Automatically Create Your NetFoundry Hosted Edge Routers</div>
        <div
            class="subhelp"
            matTooltip="By providing information on where you intend to host your services your Edge Routers will be setup in the optimal location for connecting to those services."
            matTooltipPosition="below"
        >
            How does this work?
        </div>
        <div class="sized">
            <div class="label row">
                Location of Service
                <span [hidden]="!errors['invalidAddress']" class="error">Address Not Found</span>
                <span [hidden]="errors['invalidAddress']" [ngClass]="{ error: errors['noAddress'] }" class="note"
                    >Required</span
                >
            </div>
            <div class="row">
                <span
                    class="select2 select2-container select2-container--default select2-container--below select2-container--focus"
                    dir="ltr"
                >
                    <span class="selection">
                        <span
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="select2-selection select2-selection--multiple"
                            role="combobox"
                            tabindex="-1"
                        >
                            <ul
                                [ngClass]="{
                                    error: errors['noAddress'] || errors['invalidAddress'],
                                    readOnly: processing
                                }"
                                class="select2-selection__rendered"
                            >
                                <li class="select2-search select2-search--inline"></li>

                                <li>
                                    <input
                                        (keyup)="applyFilter()"
                                        (mouseup)="applyFilter()"
                                        [(ngModel)]="address"
                                        [disabled]="processing"
                                        [ngClass]="{ empty: address === null || address === '' }"
                                        aria-autocomplete="list"
                                        autocapitalize="off"
                                        autocomplete="off"
                                        autocorrect="off"
                                        class="select2-search__field"
                                        placeholder="Address, City, Country, etc."
                                        role="textbox"
                                        spellcheck="false"
                                        tabindex="0"
                                        type="search"
                                    />
                                </li>
                            </ul>
                        </span>
                    </span>
                    <span aria-hidden="true" class="dropdown-wrapper"></span>
                </span>

                <span
                    [ngClass]="{ withBorder: !hideSelect }"
                    class="nf-select2-dropdown select2-dropdown--below"
                    dir="ltr"
                >
                    <span class="select2-results">
                        <ul
                            [hidden]="hideSelect || address === null || address === ''"
                            aria-expanded="true"
                            aria-hidden="false"
                            aria-multiselectable="true"
                            class="select2-results__options"
                            role="tree"
                        >
                            <li
                                (click)="selectOption(option)"
                                *ngFor="let option of autoFillOptions"
                                aria-selected="false"
                                class="select2-results__option"
                                role="treeitem"
                            >
                                {{ option }}
                            </li>
                        </ul>
                    </span>
                </span>
            </div>
            <div class="row buttonRow right" style="margin-top: 1.25rem">
                <button (click)="submit()" *ngIf="!processing" class="save" id="Submit">Submit</button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscZRouterClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
