<div class="row mini-pod" style="margin-top: 0.9375rem">
    <div class="label row" style="margin-bottom: 0rem; margin-top: 0.3125rem; font-size: 1.0625rem">ENABLE SOURCE TRANSPARENCY</div>
    <div
        (click)="!readOnly && toggleSourceTransparency()"
        [ngClass]="{ on: transparencyEnabled }"
        class="toggle"
        style="float: right; margin-top: 0.625rem; margin-right: 0.75rem; position: absolute; right: 0; top: 0"
    >
        <span [hidden]="!transparencyEnabled" style="margin-left: -0.9375rem">YES</span>
        <span [hidden]="transparencyEnabled" style="margin-right: -0.9375rem">NO</span>
        <div class="switch"></div>
    </div>
    <div [ngClass]="{ error: errors['sourceAddress'] }" class="label" style="color: var(--placeholder)">
        ALLOWED SOURCE ADDRESSES
    </div>
    <p-chips
        (keydown)="onSourceAddressKeyDown($event)"
        [(ngModel)]="model.data.allowedSourceAddresses"
        [allowDuplicate]="false"
        [disabled]="!transparencyEnabled || readOnly"
        [ngClass]="{ error: errors['sourceAddress'] }"
        [placeholder]="transparencyEnabled ? '10.10.0.0/24' : ''"
        addOnBlur="true"
        separator=","
    >
    </p-chips>
</div>
