<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddGatewayModal">
    <div class="title" style="filter: brightness(50%)">Create A New Gateway</div>
    <div class="subtitle">Enter your Gateway attributes</div>

    <div class="sized">
        <div class="label row">
            Gateway Name
            <span [hidden]="!errorNameLength" class="error">Name must be at least 5 characters</span>
            <span [hidden]="errorNameLength" class="note">Required</span>
        </div>
        <div class="row">
            <input
                [(ngModel)]="model.name"
                [ngClass]="{ error: errorName }"
                class="restrictNormal"
                id="EditName"
                maxlength="{{ maxLength }}"
                minlength="5"
                placeholder="What would you like to call this gateway?"
                type="text"
            />
        </div>

        <div class="label row">
            Cloud Region<span [hidden]="!errorRegion" class="error">Input Required</span
            ><span [hidden]="errorRegion" class="note">Required</span>
        </div>
        <div class="row">
            <select
                (keyup.enter)="save()"
                [(ngModel)]="model.geoRegionId"
                [ngClass]="{ error: errorRegion }"
                class="regions"
                id="RegionList"
                placeholder="Select a Region"
            >
                <option value="">Select A Region</option>
                <option *ngFor="let region of regions | sortby: 'name'" [value]="region.id">
                    {{ region.name }}
                </option>
            </select>
        </div>

        <div *ngIf="showBreakoutFields">
            <div class="label row">
                O365 Breakout Next Hop IP
                <span [hidden]="!errorNextHop" class="error">Invalid</span>
                <span [hidden]="errorNextHop" class="note">Optional</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.o365BreakoutNextHopIp"
                    [ngClass]="{ error: errorNextHop }"
                    class="restrictNormal"
                    id="NextHopIp"
                    placeholder="Next Hop IP"
                    type="text"
                />
            </div>
        </div>

        <div *ngIf="!netflowEnabled && !hideHaUpgrade && authorizationService.canCreateGatewayClusters()">
            <div class="label row">Enable High Availablity<span class="note">Enable to Edit Details</span></div>
            <div class="row">
                <div class="darkGrid">
                    <div class="title">
                        <div class="icon-ha"></div>
                    </div>
                    <div class="subtitle">
                        ENABLE HIGH AVAILABILITY<br />
                        FOR ULTIMATE REDUNDANCY
                    </div>
                    <div>
                        <div class="switchGrid">
                            <div>
                                <div (click)="toggleHAGateway()" [ngClass]="{ on: isHAGateway }" class="toggle">
                                    <span [hidden]="!isHAGateway">ON&nbsp;&nbsp;&nbsp;</span>
                                    <span [hidden]="isHAGateway">&nbsp;&nbsp;&nbsp;OFF</span>
                                    <div class="switch"></div>
                                </div>
                            </div>
                            <div>
                                <span [hidden]="!isHAGateway">Enabled</span>
                                <span [hidden]="isHAGateway">Disabled</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isHAGateway && collectors.length > 0" class="label row labelExpansion">
            Advanced Options<span class="note">Open to Edit Details</span>
        </div>
        <div *ngIf="!isHAGateway && collectors.length > 0" class="row expansion">
            <mat-accordion>
                <mat-expansion-panel #panel [hideToggle]="true">
                    <mat-expansion-panel-header [collapsedHeight]="'4.0625rem'">
                        <mat-panel-title class="expansionLabel">
                            <div class="title">
                                <div class="icon-AdvancedOptions"></div>
                            </div>
                            <div class="subtitle">Advanced Options</div>
                            <span
                                [ngClass]="{ open: panel.expanded }"
                                class="expansion-indicator icon-Sort_Down"
                            ></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <mat-grid-list cols="10" rowHeight="2.8125rem">
                        <mat-grid-tile [colspan]="9">
                            <div class="label netflowFieldLabel">Netflow</div>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1">
                            <div class="toggleTile">
                                <div
                                    (click)="toggleNetflowEnabled()"
                                    [ngClass]="{ on: netflowEnabled }"
                                    class="toggle gridToggle"
                                    id="IcmpToggle"
                                >
                                    <span [hidden]="!netflowEnabled" id="NetflowToggleOn">ON&nbsp;&nbsp;&nbsp;</span>
                                    <span [hidden]="netflowEnabled" id="NetflowToggleOff">&nbsp;&nbsp;&nbsp;OFF</span>
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                            <div class="label netflowFieldLabel">Collector</div>
                        </mat-grid-tile>
                        <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                            <select
                                [(ngModel)]="exportModel.collectorId"
                                [ngClass]="{ error: errorCollector }"
                                class="collectors netflowField"
                                id="CollectorList"
                                placeholder="Select a Netflow Collector"
                            >
                                <option value="">Select A Netflow Collector</option>
                                <option *ngFor="let collector of collectors | sortby: 'name'" [value]="collector.id">
                                    {{ collector.name }}
                                </option>
                            </select>
                        </mat-grid-tile>
                        <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                            <div class="label netflowFieldLabel">Update Frequency (in seconds)</div>
                        </mat-grid-tile>
                        <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                            <input
                                [(ngModel)]="exportModel.exportIntervalSeconds"
                                [ngClass]="{ error: errorFrequency }"
                                class="netflowField"
                                id="EditFrequency"
                                max="300"
                                min="1"
                                placeholder="the frequency the collector updates in seconds"
                                type="number"
                            />
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="row buttonRow right" style="margin-top: 1.25rem">
            <button (click)="save()" *ngIf="!processing" class="save" id="SaveGatewayButton">Create</button>
            <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
        </div>
    </div>

    <div (click)="hideForm()" class="buttonBall close" id="CloseGatewayButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div (click)="goBack()" [hidden]="isComplete" class="buttonBall back" id="BackGatewayButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
