<div class="modal solid open addCardForm" id="AddCardForm">
    <div *ngIf="!forGrowth" class="title modalTitle">Extend your FREE Trial</div>
    <div *ngIf="forGrowth" class="title modalTitle">Credit Card Required</div>
    <div *ngIf="!forGrowth" class="form_description">
        Add a credit card now to maintain your network and receive another 30 days FREE. After which your monthly
        pricing is $199.00 USD/Network. You may cancel at any time via your billing dashboard.
    </div>
    <div *ngIf="forGrowth" class="form_description">
        In order to upgrade to a Growth tier account you will first need to add a valid credit card. This card will be
        billed at the rate of $5 per endpoint/month. You may cancel at any time via your billing dashboard.
    </div>
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="cardArea">
        <div [hidden]="isLoading" class="cardContainer">
            <div [ngClass]="{ isUpgrade: forGrowth }" class="card">
                <div class="title">Payment Method <span class="ccCardIcon desktop"></span></div>
                <div id="CardUpdate">
                    <form #ngForm="ngForm" (ngSubmit)="onTokenize($event)" [formGroup]="billingForm" name="billingForm">
                        <div class="spaceDiv row"></div>
                        <div class="contents row">
                            <!--div id="ccCard"></div -->

                            <div
                                (ready)="onReady($event)"
                                cbCardField
                                class="chargebee-input-card fieldset field"
                                id="card-field"
                            ></div>
                        </div>
                        <div class="contents row">
                            <input
                                [ngClass]="{
                                    error: ngForm.submitted && billingForm.get('firstName').invalid
                                }"
                                class="form_input"
                                formControlName="firstName"
                                id="BillingFirstName"
                                maxlength="300"
                                name="firstName"
                                placeholder="* First Name"
                                type="text"
                            />
                        </div>
                        <div class="contents row">
                            <input
                                [ngClass]="{
                                    error: ngForm.submitted && billingForm.get('lastName').invalid
                                }"
                                class="form_input"
                                formControlName="lastName"
                                height="2.8125rem"
                                id="BillingLastName"
                                maxlength="300"
                                name="lastName"
                                placeholder="* Last Name"
                                type="text"
                            />
                        </div>
                        <div *ngIf="!forGrowth" class="contents row">
                            <input
                                [ngClass]="{
                                    error: ngForm.submitted && billingForm.get('email').invalid
                                }"
                                class="form_input"
                                formControlName="email"
                                id="BillingEmail"
                                maxlength="300"
                                name="email"
                                placeholder="* Email"
                                type="text"
                            />
                        </div>
                    </form>
                </div>
                <div [hidden]="!obtainSourceError || isLoading" class="noarea" id="NoArea">
                    <div class="icon ccCardIcon"></div>
                    <div class="center">Unable to obtain card information</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row buttonRow center">
        <button *ngIf="canUpdateBilling" (click)="onTokenize($event)" class="save" id="SaveButton">
            Submit Payment
        </button>
        <button *ngIf="isLoading" class="save confirm" disabled>Submiting <span class="spinner"></span></button>
    </div>
</div>
