<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="AddServiceModal">
    <div [hidden]="isEditing" class="title" style="filter: brightness(50%)">Create A New Voice Enhanced Service</div>
    <div [hidden]="isAdding" class="title" style="filter: brightness(50%)">Edit Your Voice Enhanced Service</div>
    <div class="subtitle">Enter your service attributes</div>
    <div class="sized">
        <div class="label row">
            Service Name<span [hidden]="!errors['name']" class="error" id="NameError">{{ nameErrorString }}</span
            ><span [hidden]="errors['name']" class="note" id="NameNote">Required</span>
        </div>
        <div class="row">
            <input
                [(ngModel)]="model.name"
                [disabled]="!canEdit"
                [ngClass]="{ error: errors['name'] }"
                class="restrictNormal"
                id="EditName"
                maxlength="64"
                placeholder="What would you like to call this service?"
                type="text"
            />
        </div>

        <div class="label row">
            Gateway<span [hidden]="!errors['endpointId']" class="error" id="GatewayListError">Required</span
            ><span [hidden]="errors['endpointId']" class="note" id="GatewayListNote">Required</span>
        </div>
        <div class="row">
            <select
                [(ngModel)]="model.endpointId"
                [disabled]="disableGatewaySelect || !canEdit"
                [ngClass]="{ error: errors['endpointId'] }"
                id="GatewayList"
                placeholder="Select a Gateway"
            >
                <option [ngValue]="null">Select a Voice Enhanced Gateway</option>
                <option *ngFor="let gateway of l2gGateways" [value]="gateway.id" id="{{ gateway.id }}">
                    {{ gateway.name }}
                </option>
            </select>
        </div>

        <div class="label row">
            Bridge<span [hidden]="!errors['endpointId']" class="error" id="GatewayListError">Required</span
            ><span [hidden]="errors['endpointId']" class="note" id="GatewayListNote">Required</span>
        </div>
        <div class="row">
            <input [(ngModel)]="model.bridgeIntf" disabled placeholder="br0" />
        </div>

        <div class="label row">
            VLAN
            <div
                class="infoicon"
                matTooltip="This value needs to be between 1 and 1024 and cannot be the same for multiple L2 gateway services."
                matTooltipPosition="below"
            ></div>
            <span class="note" id="GatewayListNote">Required</span>
        </div>

        <div class="row">
            <input [(ngModel)]="model.vlanId" placeholder="11" />
        </div>

        <div class="row right buttonRow" style="margin-top: 1.25rem">
            <button (click)="save()" *ngIf="!processing && canEdit" class="save" id="SaveServiceButton">
                <span *ngIf="isEditing">Save</span>
                <span *ngIf="isAdding">Create</span>
            </button>
            <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
        </div>
    </div>

    <div (click)="hideForm()" class="buttonBall close" id="CloseServiceButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div (click)="goBack()" [hidden]="!allowBack" class="buttonBall back" id="BackServiceButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
