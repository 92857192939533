<div class="label row">
    {{ label }}
    <div [title]="toolTip" class="infoicon" matTooltipPosition="below"></div>
    <span class="note">{{ required ? 'REQUIRED' : 'OPTIONAL' }}</span>
</div>
<div [ngClass]="{ disabled: disabled }" class="row">
    <div class="toggle-pod">
        <span class="toggle-text">{{ description }}</span>
        <div
            (click)="!disabled && onClick()"
            [ngClass]="{ on: model[propertyName] }"
            class="toggle"
            style="float: right; margin-top: 0.9375rem; margin-right: 0.75rem"
        >
            <span [hidden]="!model[propertyName]" style="margin-left: -0.9375rem">YES</span>
            <span [hidden]="model[propertyName]" style="margin-right: -0.9375rem">NO</span>
            <div class="switch"></div>
        </div>
    </div>
</div>
