<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="GroupAddModal">
    <div class="fullRow" id="Stage1">
        <div [hidden]="!isAdding" class="title" style="filter: brightness(50%)">Create A New Endpoint Group</div>
        <div [hidden]="!isEditing" class="title" style="filter: brightness(50%)">Edit Your Endpoint Group</div>
        <div class="subtitle">Enter your endpoint group attributes</div>
        <div *ngIf="zitiEnabledService.zitiEnabled" class="subtitle zitiNote">
            NOTE: Ziti clients cannot be added to a group. They will be excluded from the list of clients.
        </div>
        <div [ngClass]="{ disable: processing }" class="formArea">
            <div class="sized">
                <div class="label row">
                    GROUP NAME
                    <span [hidden]="!errorName" class="error">Required Field</span>
                    <span [hidden]="errorName" class="note">Required</span>
                </div>
                <div class="row">
                    <input
                        [(ngModel)]="model.name"
                        [disabled]="!isAdding && !canUpdate"
                        [ngClass]="{ error: errorName }"
                        class="restrictNormal"
                        id="GroupName"
                        maxlength="64"
                        placeholder="What would you like to call this group?"
                    />
                </div>
            </div>
            <div class="sized">
                <div class="full">
                    <div class="row">
                        <div class="label curved">Add New Clients/Gateways</div>
                        <div class="row" style="padding-bottom: 1.25rem">
                            <div (click)="clearFilter()" class="clearFilter" id="ClearClients"></div>
                            <input
                                [(ngModel)]="searchString"
                                class="curved"
                                id="SearchClients"
                                placeholder="Search your Clients and Gateways"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="listSized">
                <div class="full">
                    <div class="grid selectors">
                        <div>
                            <div *ngIf="!zitiEnabledService.zitiPure" class="subcontent center">Available Clients</div>
                            <div
                                *ngIf="!zitiEnabledService.zitiPure"
                                class="formList"
                                data-to="SelectedClients"
                                id="AllClients"
                                style="height: 11.3125rem"
                            >
                                <app-pickbox
                                    #availableClientsPicker
                                    (itemSelected)="moveTo($event)"
                                    [height]="'100%'"
                                    [identifier]="'AvailableClients'"
                                    [isLoading]="clientsLoading"
                                    [items]="clients"
                                    [resourceType]="'client'"
                                    [scrollable]="true"
                                    [searchString]="searchString"
                                ></app-pickbox>
                            </div>
                            <div class="subcontent center" style="margin-top: 1.25rem">Available Gateways</div>
                            <div class="formList" data-to="SelectedClients" id="AllGateways" style="height: 11.3125rem">
                                <app-pickbox
                                    #availableGatewaysPicker
                                    (itemSelected)="moveTo($event)"
                                    [height]="'100%'"
                                    [iconClass]="'itemGraphic icon-GW'"
                                    [identifier]="'AvailableGateways'"
                                    [isLoading]="gatewaysLoading"
                                    [items]="gateways"
                                    [resourceType]="'gateway'"
                                    [scrollable]="true"
                                    [searchString]="searchString"
                                ></app-pickbox>
                            </div>
                        </div>
                        <div class="moveButtons">
                            <div
                                (click)="removeAll()"
                                *ngIf="isAdding || canUpdate"
                                class="arrow moveLeft"
                                id="SelectedClients_Arrow"
                            ></div>
                            <div
                                (click)="addAll()"
                                *ngIf="isAdding || canUpdate"
                                class="arrow moveRight"
                                id="AllClients_Arrow"
                            ></div>
                        </div>
                        <div>
                            <div class="subcontent center">Selected Clients/Gateways</div>
                            <div class="formList selectedHeight" data-to="variable" id="SelectedClients">
                                <div *ngIf="!zitiEnabledService.zitiPure">
                                    <div
                                        (click)="moveFrom(endpoint)"
                                        *ngFor="let endpoint of selected | sortby: 'name'"
                                        [hidden]="endpoint.endpointType === 'CL'"
                                        class="listItem"
                                        id="SelectedEndpoint_{{ endpoint.id }}"
                                    >
                                        <div class="itemGraphic icon-{{ getType(endpoint.endpointType) }}"></div>
                                        <app-resource-status
                                            [item]="endpoint"
                                            [resourceType]="'gateway'"
                                            [showName]="false"
                                        >
                                        </app-resource-status>
                                        {{ endpoint.name }}
                                    </div>
                                </div>
                                <div
                                    (click)="moveFrom(endpoint)"
                                    *ngFor="let endpoint of selected | sortby: 'name'"
                                    [hidden]="endpoint.endpointType !== 'CL'"
                                    class="listItem"
                                    id="SelectedEndpoint_{{ endpoint.id }}"
                                >
                                    <div class="itemGraphic icon-{{ getType(endpoint.endpointType) }}"></div>
                                    <app-resource-status
                                        [isZiti]="endpoint.endpointType === 'ZTCL'"
                                        [item]="endpoint"
                                        [resourceType]="'client'"
                                        [showName]="false"
                                    >
                                    </app-resource-status>
                                    {{ endpoint.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="listSized">
            <div class="full">
                <div class="row buttonRow full right">
                    <button
                        (click)="save()"
                        *ngIf="!processing && (isAdding || canUpdate)"
                        class="save"
                        id="SaveGroupButton"
                    >
                        <span *ngIf="isEditing">Save</span>
                        <span *ngIf="isAdding">Create</span>
                    </button>
                    <button *ngIf="processing && (isAdding || canUpdate)" class="save confirm" disabled>
                        Saving <span class="spinner"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscGroupClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
