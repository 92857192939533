<div class="modal solid open" id="NetworkV2InfoModal">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Network Support Info</div>
    <div class="icon" style="background-image: url(/assets/svgs/EditNetwork.svg)"></div>
    <div class="subtitle">
        Ziti Details - <strong>{{ networkModel.name }}</strong>
    </div>

    <div class="content">
        <div>
            <label>Controller IP Address</label>
            <div>{{ item['ips'] }}</div>
        </div>
        <div>
            <label>ZITI API Username</label>
            <div style="font-size: 1rem">{{ zitiCredentials.zitiUserId }}</div>
        </div>
        <div>
            <label>ZITI API Password</label> <span style="font-size: 1rem">{{ zitiCredentials.zitiPassword }}</span>
        </div>

        <div class="blocktitle">
            Authenticate via URL POST
            <span (click)="copy('zitiurl')" class="copyBtn" title="Copy to clipboard"></span>
        </div>
        <pre id="zitiurl">{{ zitiurl }}</pre>

        <div class="blocktitle">
            Request Payload
            <span (click)="copy('ziticreds')" class="copyBtn" title="Copy to clipboard"></span>
        </div>
        <pre id="ziticreds"
            >{{ '{' }}
      "username": "{{ zitiCredentials.zitiUserId }}"
  "password": "{{ zitiCredentials.zitiPassword }}"
      {{ '}' }}</pre
        >

        <div class="blocktitle">
            Authenticate via URL CLI
            <span (click)="copy('ziticli')" class="copyBtn" title="Copy to clipboard"></span>
        </div>
        <pre id="ziticli">{{ ziticli }}</pre>
    </div>
    <div class="buttons">
        <button (click)="close()" class="save" id="closeSupportInfoButton">Close</button>
    </div>
</div>
