<div class="modal solid open">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Posture Data</div>
    <div *ngIf="postureData" class="subtitle">Current Data for {{ postureData.endpoint.name }}</div>
    <span *ngIf="lastCollected" class="updatedTag">Last Collected {{ formatTime(lastCollected) }}</span>
    <div class="icon" style="background-image: url(/assets/svgs/No_Posture.svg)"></div>
    <div
        *ngIf="mfaStatus === true"
        class="icon mfa-icon"
        style="background-image: url(/assets/svgs/MFA-Active.svg)"
    ></div>
    <div class="viewgrid">
        <div (click)="toggleJsonView()" [ngClass]="{ on: isFormView }" class="jsonView">JSON VIEW</div>
        <div (click)="toggleJsonView()" [ngClass]="{ on: isFormView }" class="toggle">
            <div class="switch"></div>
        </div>
        <div (click)="toggleJsonView()" class="formView">FORM VIEW</div>
    </div>
    <div></div>

    <div *ngIf="isFormView" class="quarterGrid">
        <mat-card appearance="outlined" class="specificPostureCheckData">
            <span
                *ngIf="
                    postureData &&
                    postureData.postureData.domain.postureCheckId &&
                    postureData.postureData.domain.timedOut === true
                "
                class="timedOutTag"
                >Timed Out</span
            >
            <img class="postureCheckImage" mat-card-sm-image src="/assets/svgs/DomainCheck.svg" />
            <mat-card-header class="cardHeader">
                <mat-card-title class="titleClass">Domain Check</mat-card-title>
            </mat-card-header>
            <mat-card-content class="cardContent">
                <div *ngIf="domainPostureCheck">
                    <span class="postureCheckName">{{ domainPostureCheck.domain }} </span>
                    <br />
                    <strong>Customer Configured Domain(s):</strong>
                    <p *ngFor="let domain of domainPostureCheck.data.domains">
                        {{ domain }}
                    </p>
                    <br />
                    <strong>Returned Domain(s):</strong>
                    <br />

                    {{ postureData.postureData.domain.domain }}
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="specificPostureCheckData">
            <span
                *ngIf="
                    postureData &&
                    postureData.postureData.postureCheckId &&
                    postureData.postureData.domain.timedOut === true
                "
                class="timedOutTag"
                >Timed Out</span
            >
            <img class="postureCheckImage" mat-card-sm-image src="/assets/svgs/ProcessCheck.svg" />
            <mat-card-header class="cardHeader">
                <mat-card-title class="titleClass">Process Check</mat-card-title>
            </mat-card-header>
            <mat-card-content class="cardContent">
                <p *ngFor="let process of processPostureChecks">
                    <span class="postureCheckName">{{ process.postureCheck.name }}</span>
                    <br />
                    <span *ngIf="process.process.isRunning === true" style="color: var(--green); line-height: 1.875rem"
                        ><strong>Running</strong></span
                    >
                    <span *ngIf="process.process.isRunning === false" style="color: var(--red); line-height: 1.875rem"
                        ><strong>Not Running</strong></span
                    >
                    <br />
                    <button
                        *ngIf="process.process.binaryHash"
                        color="primary"
                        mat-stroked-button
                        matTooltip="{{ process.process.binaryHash }}"
                        matTooltipClass="processTooltip"
                    >
                        Binary Hash
                    </button>
                    <br />
                </p>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="specificPostureCheckData">
            <span *ngIf="postureData && postureData.postureData.mac.timedOut === true" class="timedOutTag"
                >Timed Out</span
            >
            <img class="postureCheckImage" mat-card-sm-image src="/assets/svgs/maccheck.svg" />
            <mat-card-header class="cardHeader">
                <mat-card-title class="titleClass">MAC Address Check</mat-card-title>
            </mat-card-header>
            <mat-card-content
                *ngIf="
                    postureData &&
                    postureData.postureData.mac.addresses &&
                    postureData.postureData.mac.addresses.length >= 1
                "
                class="cardContent"
            >
                <div *ngIf="macPostureCheck">
                    <span class="postureCheckName">{{ macPostureCheck.name }}</span>
                    <p *ngFor="let address of postureData.postureData.mac.addresses">
                        {{ address }}
                    </p>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="specificPostureCheckData">
            <span
                *ngIf="
                    postureData &&
                    postureData.postureData.postureCheckId &&
                    postureData.postureData.os.timedOut === true
                "
                class="timedOutTag"
                >Timed Out</span
            >
            <img class="postureCheckImage" mat-card-sm-image src="/assets/svgs/oscheck.svg" />
            <mat-card-header class="cardHeader">
                <mat-card-title class="titleClass">OS Check</mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="osPostureCheck" class="cardContent">
                <span class="postureCheckName">{{ osPostureCheck.name }}</span>
                <br />
                <strong>Type:</strong> {{ postureData.postureData.os.type }}
                <br />
                <strong>Version:</strong> {{ postureData.postureData.os.version }}
            </mat-card-content>
        </mat-card>
    </div>

    <div *ngIf="!isFormView" class="row" style="overflow: auto; height: 25rem; margin-top: 2.5rem; padding-top: 0rem">
        <pre><code [highlight]="codePostureData"></code></pre>
    </div>
    <div class="buttons">
        <button (click)="close()" class="save" id="CloseNetworkInfoButton">OK Thanks</button>
    </div>
</div>
